import get from "lodash/get";

export const RECORDING = "Recording";
export const VIDEO = "Video";
export const IMAGE = "Image";
export const SOURCE_FILE = "SourceFile";
export const TEXT = "Text";

export function getFileCategoryType(mimetype = "", fileType = "") {
  if (textFileTypes.includes(fileType)) {
    return TEXT;
  }
  if (mimetype.includes("audio")) {
    return RECORDING;
  }
  if (mimetype.includes("video")) {
    return VIDEO;
  }
  if (mimetype.includes("image")) {
    return IMAGE;
  }
  if (mimetype.includes("text")) {
    return TEXT;
  }

  return SOURCE_FILE;
}

export function isSourceFile(component) {
  const mimeType = get(component, "file[0].mimeType", "");
  const fileType = get(component, "file[0].fileType", "");

  if (!mimeType && !fileType) {
    return true;
  }
  return getFileCategoryType(mimeType, fileType) === SOURCE_FILE;
}

const textFileTypes = [
  "CSV",
  "DOC",
  "DOCX",
  "PAGES",
  "PDF",
  "PPT",
  "PPTX",
  "RTF",
  "TXT",
  "XLL",
  "XLS",
  "XLSX"
]

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { selectTaskById } from "./tasksSlice";
import { normalize } from "normalizr";
import { commentsSchema, commentEntity } from "../schemas";
import {
  readEntity,
  createEntity,
  updateEntity,
  deleteEntity,
} from "../../data/delicDataClient";
import {
  upsertCommentsFromCommentables,
  upsertComments,
  createCommentSuccess,
} from "../actions";
import { selectComponentById } from "./componentsSlice";
import get from "lodash/get";

const commentsAdapter = createEntityAdapter();

export const slice = createSlice({
  name: "comments",
  initialState: commentsAdapter.getInitialState(),
  reducers: {
    fetchCommentsStart(state) {
      state.isLoading = true;
    },
    fetchCommentsSuccess(state, { payload }) {
      state.isLoading = false;
      const { comments } = payload;

      commentsAdapter.upsertMany(state, comments);
    },
    updateCommentSuccess(state, { payload }) {
      const { comment } = payload;
      const normalized = normalize(comment, commentEntity);
      commentsAdapter.upsertOne(
        state,
        normalized.entities.comments[comment.id]
      );
    },
    deleteCommentSuccess(state, { payload }) {
      const { commentId } = payload;
      commentsAdapter.removeOne(state, commentId);
    },
    onFail(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upsertCommentsFromCommentables, (state, { payload }) => {
      const { comments } = payload;
      const normalized = normalize({ comments }, commentsSchema);
      if (normalized.entities.comments) {
        commentsAdapter.upsertMany(state, normalized.entities.comments);
      }
    });
    builder.addCase(upsertComments, (state, { payload }) => {
      const { comments } = payload;
      if (comments) {
        commentsAdapter.upsertMany(state, comments);
      }
    });
    builder.addCase(createCommentSuccess, (state, { payload }) => {
      const { comment } = payload;
      const normalized = normalize(comment, commentEntity);
      commentsAdapter.addOne(state, normalized.entities.comments[comment.id]);
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const {
  onFail,
  updateCommentSuccess,
  deleteCommentSuccess,
  fetchCommentsSuccess,
} = slice.actions;

export const {
  selectById: selectCommentById,
  selectIds: selectCommentIds,
  selectEntities: selectCommentEntities,
  selectAll: selectAllComments,
  selectTotal: selectTotalComments,
} = commentsAdapter.getSelectors((state) => state.comments);

export const selectCommentsByCommentableId = (commentableId) =>
  createSelector(
    [
      (state) => {
        const task = selectTaskById(state, commentableId);
        if (task) {
          return task;
        }
        const component = selectComponentById(state, commentableId);
        if (component) {
          return component;
        }
      },
      (state) => state.comments.entities,
    ],
    (commentable, comments) => {
      if (commentable) {
        const commentableComments = get(commentable, "comments", []);
        return commentableComments.reduce(
          (arr, id) => (comments[id] ? arr.concat(comments[id]) : arr),
          []
        );
      }
      return [];
    }
  );

// ------------------- CREATE ------------------ //

export const createComment = ({ comment, commentableId }) => async (
  dispatch
) => {
  try {
    const newComment = await createEntity("Comment", comment);
    if (!newComment) {
      dispatch(onFail("Error when creating comment"));
    } else {
      dispatch(createCommentSuccess({ comment: newComment, commentableId }));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

// ------------------- UPDATE ------------------ //

export const updateComment = ({ personId, text, commentId }) => async (
  dispatch
) => {
  try {
    const payload = {
      personId,
      input: {
        text,
        id: commentId,
      },
    };
    const comment = await updateEntity("Comment", payload);

    if (!comment) {
      dispatch(onFail("Didn't update comment"));
    }

    dispatch(updateCommentSuccess({ comment }));
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

export const deleteComment = ({ personId, commentId, commentableId }) => async (
  dispatch
) => {
  try {
    const payload = {
      personId: personId,
      commentId,
    };
    const deletedComment = await deleteEntity("Comment", payload);

    if (!deletedComment) {
      dispatch(onFail("Didn't delete comment"));
    }

    dispatch(deleteCommentSuccess({ commentId, commentableId }));
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

export const fetchComments = ({ commentableId }) => async (dispatch) => {
  try {
    const payload = {
      componentId: commentableId,
    };
    const componentWithComments = await readEntity(
      "ComponentComments",
      payload
    );

    if (!componentWithComments) {
      dispatch(onFail("Fetch of components failed"));
    }
    const { result, entities } = normalize(
      componentWithComments,
      commentsSchema
    );

    dispatch(
      fetchCommentsSuccess({
        comments: entities.comments,
        ids: result.comments,
        commentableId,
      })
    );
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

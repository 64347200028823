import React, { useCallback, useReducer } from "react";
import { fetchReferences } from "./referencesService";

export const ReferencesDispatch = React.createContext(null);

export const ReferencesDispatcher = ({ children }) => {

    const reducer = useCallback((state, action) => {
        switch (action.type) {
            case "fetch":
                fetchReferences(referencesDispatch, action.payload);
                return state;
            case "fetched":
                return action.payload;
            default:
                return state;
        }
    }, []);

    const [references, referencesDispatch] = useReducer(reducer, null);

    return (
        <ReferencesDispatch.Provider
            value={{
                references,
                referencesDispatch,
            }}
        >
            {children}
        </ReferencesDispatch.Provider>
    );
};

import React, {useCallback, useReducer} from "react";
import { fetchLives } from "./privateLivesService";

export const PrivateLivesDispatch = React.createContext(null);

export const PrivateLivesDispatcher = ({children}) => {

    const reducer = useCallback( (state, action) => {
        switch (action.type) {
            case "fetch":
                fetchLives(livesDispatch, action.payload);
                return state;
            case "fetched":
                return action.payload;
            default:
                return state;
        }
    }, []);

    const [lives, livesDispatch] = useReducer(reducer, []);

    return (
        <PrivateLivesDispatch.Provider
            value={{
                lives,
                livesDispatch,
            }}
        >
            {children}
        </PrivateLivesDispatch.Provider>
    );
};

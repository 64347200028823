import { signOut } from "./signOut"

export const notEmpty = (array) => {
  return (array && array.length > 0)
};

export const isEmpty = (array) => {
  return (!array || array.length === 0)
};

export const objIsEmpty = (obj) => {
  return !obj || (obj && Object.entries(obj).length === 0);
};

export const objIsNotEmpty = (obj) => {
  return obj && Object.entries(obj).length !== 0;
};

export const emailIsValid = email => /^\S+@\S+$/.test(email)

export const handleError = (e) => {
  if (e && e.contains && e.contains("No credentials")) {
    signOut()
  }
  console.warn(e);
}

export function preventDefault(e) {
  e.preventDefault();
}

export const DELIC_FULL_URL = window.location.protocol + "//" + window.location.host;
export const userInvitationUrl = DELIC_FULL_URL + "/invitation/"
export const USER_SHARE_URL = DELIC_FULL_URL + "/public-share/"

export function isMemberPending(member) {
  if (!member) {
    return true
  }
  return member.authStatus === "INVITED"
}

export function getHiddenLetters(emailLenght) {
  return "*".repeat(emailLenght - 5);
}

export function obscureEmail(email) {
  if (!email) return "";
  const emailLenght = email.length;
  if (emailLenght < 6) return email;
  return email.replace(email.substring(2, emailLenght - 3), getHiddenLetters(emailLenght));
}

import {
  generateFileTypeEnum,
  getFileExtension,
  getGroupFromFileType,
} from "../../utils/fileUploadHelpers";
import {
  groups as FileFormatGroups,
  zippableSoftwareFolders,
} from "../../content/workFileFormatsGroups";
import { notEmpty } from "../../utils/basic";
import { computeComponentVersion } from "../../components/Component/helpers";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { createEntity } from "../../data/delicDataClient";
import { getLastNumber } from "../../components/Component/helpers";
import sortBy from "lodash/sortBy";
import {
  getFileCategoryType,
  RECORDING,
  VIDEO,
  isSourceFile,
} from "../../utils/fileUtils";

/*
Get all the components either in a project or in all the projects and dashboard
This is used in the add files and manage files
 */
export function getSameSpaceGroupedComponents(
  newProjectId,
  allProjectsGroupedComponents
) {
  let groupedComponents = [];

  const project =
    allProjectsGroupedComponents &&
    allProjectsGroupedComponents.find((proj) => proj.id === newProjectId);
  if (project) {
    project.components.forEach((comp) => {
      groupedComponents.push({
        ...comp,
        projectId: project.id,
        projectName: project.title,
      });
    });
  }

  return groupedComponents.map((groupedComp) => {
    const comp = get(groupedComp, "component[0]", groupedComp);
    const groupLabels = {
      componentGroup: get(groupedComp, "componentGroup", 0),
      componentGroupOrderWeight: get(
        groupedComp,
        "componentGroupOrderWeight",
        0
      ),
      componentOrderWeight: get(groupedComp, "componentOrderWeight", 0),
      projectId: get(groupedComp, "projectId", "Dashboard"),
      projectName: get(groupedComp, "projectName", "Dashboard"),
    };
    return { ...comp, groupLabels };
  });
}

/*
Create the groups of components, the result is an object with the groups as key.
If it's the dashboard is not going to create it
This is needed when adding them to get the proper group and version
 */
export function getComponentsInSelectedProjectByGroupAndOrdered({
  projectId,
  groupedComponents,
}) {
  if (projectId === "Dashboard") return {};
  const groups = {};
  groupedComponents.forEach((componentWithLabel) => {
    if (groups[componentWithLabel.groupLabels.componentGroup]) {
      groups[componentWithLabel.groupLabels.componentGroup].push(
        componentWithLabel
      );
    } else {
      groups[componentWithLabel.groupLabels.componentGroup] = [
        componentWithLabel,
      ];
    }
  });
  Object.keys(groups).forEach((key) => {
    const ordered = sortBy(groups[key], [
      function(o) {
        return -o.groupLabels.componentOrderWeight;
      },
    ]);
    groups[key] = ordered;
  });

  return groups;
}

export function getLatestVersionsOfComponents(newSameSpaceGroupedComponents) {
  const latestVersions = [];
  newSameSpaceGroupedComponents.forEach((comp) => {
    if (notEmpty(comp.versions)) {
      const sortedVersionsLatestFirst = [...comp.versions].sort((a, b) => { return getLastNumber(b.version) - getLastNumber(a.version) })
      latestVersions.push({
        ...sortedVersionsLatestFirst[0],
        groupLabels: comp.groupLabels,
      });
    } else {
      latestVersions.push(comp);
    }
  });
  return latestVersions;
}

//
/*
This is to get the versions options for each uploaded file,
it's created here since we need to get this list when uploading new files in case it's a version of a component
 */
export function getSelectableVersionsOptions(
  newProjectId,
  newLatestVersionsOfComponents
) {
  const options = [
    {
      value: "",
      label: "No version",
      type: "None",
      projectId: newProjectId,
      projectName: "",
      version: "",
    },
  ];

  newLatestVersionsOfComponents.forEach((comp) => {
    options.push({
      value: comp.id,
      label: comp.title,
      type: comp["__typename"],
      projectId: get(comp, "groupLabels.projectId", "Dashboard"),
      projectName: get(comp, "groupLabels.projectName", "Dashboard"),
      version: `${getLastNumber(comp.version)}`,
    });
  });

  return options;
}

function getTitle(fileName, fileExtension, mimeType) {
  if (!fileExtension) return fileName;

  if (fileName.endsWith(".zip") && mimeType === "application/zip") {
    if (zippableSoftwareFolders.some((ext) => ext === fileExtension)) {
      return fileName.substring(0, fileName.indexOf(fileExtension));
    }
    return fileName.substring(0, fileName.indexOf(".zip"));
  }
  return fileName.substring(0, fileName.indexOf(fileExtension));
}

const getComponentGroup = (fileExtension, mimeType) => {
  let componentGroup = getGroupFromFileType(fileExtension, mimeType);
  if (!componentGroup) {
    const mimeTypeIsAudio =
      mimeType.includes("audio") || mimeType.includes("video");
    if (mimeTypeIsAudio) {
      componentGroup = FileFormatGroups.AUDIO_FILE;
    }
  }
  return componentGroup;
};

export function getPayloads({
  files,
  personId,
  targetParentComponent,
  targetLatestVersionComponent,
}) {
  return files.reduce((arr, fileWrap) => {
    const notSerializableFile = fileWrap.file;
    const file = {
      size: notSerializableFile.size,
      lastModified: notSerializableFile.lastModified,
      type: notSerializableFile.type,
      name: notSerializableFile.name,
      path: notSerializableFile.path,
    };
    const fileName = file.name || "";
    const mimeType = file.type || "";
    const fileExtension = getFileExtension(fileName, fileWrap.ext);
    const fileExtensionEnum = generateFileTypeEnum(fileExtension);
    const title = getTitle(fileName, fileExtension, mimeType);
    const componentGroup = getComponentGroup(fileExtension, mimeType);
    const isFileSourceFile = isSourceFile({
      file: [{ fileType: fileExtensionEnum, mimeType }],
    });

    const metadata = {
      contentLength: file.size.toString(),
      lastModified: file.lastModified.toString(),
    };
    const storageInput = {
      file,
      metadata,
      contentType: file.type,
    };

    const componentInput = {
      title: title && title.trim(),
      version: "1",
    };
    const fileInput = {
      fileName: file.name,
      fileType: fileExtensionEnum,
      mimeType: file.type,
      size: file.size,
    };

    const payload = {
      partyId: personId,
      projectId: "",
      componentInput,
      fileInput,
      componentGroup: componentGroup.trim(),
      componentGroupOrderWeight: 0,
      componentOrderWeight: 0,
    };

    const fileCategoryType = getFileCategoryType(
      fileInput.mimeType,
      fileInput.fileType
    );
    const type = fileCategoryType;

    let input = {
      payload,
      sourceFile: isFileSourceFile,
      storageInput,
      fileInput,
      type,
      isUploading: false,
      isUploaded: false,
      isIdea: false,
      idForComparison: uuid(),
      label: fileCategoryType,
      isWorkRequired:
        fileCategoryType === RECORDING || fileCategoryType === VIDEO,
    };

    // This code is only triggered when the user open the panel from the upload version button in a component
    if (targetParentComponent && targetLatestVersionComponent) {
      input.payload.parentComponentId = get(targetParentComponent, "id", "");
      input.payload.workId = get(targetParentComponent, "work[0].id", "");
      input.payload.latestVersionComponentId = targetLatestVersionComponent.id;
      input.payload.componentInput.version = computeComponentVersion(
        targetLatestVersionComponent.version
      ).increase;
      input.previousTitle = input.payload.componentInput.title;
      input.payload.componentInput.title = get(
        targetParentComponent,
        "title",
        ""
      );
      input.previousGroup = input.payload.componentGroup;
      input.payload.componentGroup = get(
        targetParentComponent,
        "componentGroup",
        ""
      );
      input.payload.componentGroupOrderWeight = get(
        targetParentComponent,
        "componentGroupOrderWeight",
        ""
      );
      input.payload.componentOrderWeight = get(
        targetParentComponent,
        "componentOrderWeight",
        ""
      );
      input.isVersion = true;
    }

    return arr.concat(input);
  }, []);
}

export function getFirstShareableComponent(
  uploadedFiles = [],
  importedFiles = []
) {
  for (let i = 0; i < uploadedFiles.length; i++) {
    const file = uploadedFiles[i];
    if (!file.isIdea) {
      return get(file, "payload.componentInput.id", "");
    }
  }

  for (let i = 0; i < importedFiles.length; i++) {
    const file = importedFiles[i];
    if (!file.isIdea) {
      return get(file, "payload.componentInput.id", "");
    }
  }
}

export function addInput({
  input,
  selectedProjectId,
  previouslyAddedFiles,
  sameSpaceComponentsByGroupAndOrdered,
}) {
  let payload = { ...input.payload, projectId: selectedProjectId };
  const targetGroupList =
    sameSpaceComponentsByGroupAndOrdered[payload.componentGroup];
  const {
    componentOrderWeight,
    componentGroupOrderWeight,
  } = getComponentGroupAndWeights({
    selectedProjectId,
    targetGroupList,
  });

  // Check if uploading multiple files as version of a single component and update the version number
  const sameComponentTarget = (previouslyAddedFiles || []).filter(
    (input) =>
      get(input, "payload.parentComponentId", "") === payload.parentComponentId
  );

  if (sameComponentTarget.length > 0) {
    let newVersion = payload.componentInput.version;
    for (let i = 0; i < sameComponentTarget.length; i++) {
      newVersion = computeComponentVersion(newVersion).increase;
    }
    const componentInput = { ...payload.componentInput, version: newVersion };
    payload = { ...payload, componentInput };
  }

  const checkedProjectId =
    payload.projectId === "Dashboard" ? "" : payload.projectId;
  const updatedPayload = {
    ...payload,
    label: input.label,
    componentOrderWeight,
    componentGroupOrderWeight,
    projectId: checkedProjectId,
  };

  if (input.isIdea) {
    if (input.isVersion) {
      if (input.isWorkRequired) {
        return createEntity("VersionOfReferenceWithWork", updatedPayload);
      }
      return createEntity("VersionOfReference", updatedPayload);
    }
    if (input.isWorkRequired) {
      return createEntity("ReferenceWithWork", updatedPayload);
    }
    return createEntity("Reference", updatedPayload);
  }

  if (input.isVersion) {
    if (input.isWorkRequired) {
      return createEntity("VersionOfComponentWithWork", updatedPayload);
    }
    return createEntity("VersionOfComponent", updatedPayload);
  }
  if (input.isWorkRequired) {
    return createEntity("ComponentWithWork", updatedPayload);
  }
  return createEntity("Component", updatedPayload);
}

export function getComponentGroupAndWeights({
  selectedProjectId,
  targetGroupList,
}) {
  if (selectedProjectId === "Dashboard" || !targetGroupList) {
    return {
      componentOrderWeight: 0,
      componentGroupOrderWeight: 0,
    };
  }
  return {
    componentOrderWeight: getComponentHigherOrder(targetGroupList),
    componentGroupOrderWeight: getComponentHigherGroupOrder(targetGroupList),
  };
}

function getComponentHigherOrder(targetGroupList) {
  if (notEmpty(targetGroupList)) {
    const position = get(
      targetGroupList,
      "[0].groupLabels.componentOrderWeight",
      0
    );
    return position !== 0 ? position + 1 : 0;
  }
  return 0;
}

function getComponentHigherGroupOrder(targetGroupList) {
  if (notEmpty(targetGroupList)) {
    return get(targetGroupList, "[0].groupLabels.componentGroupOrderWeight", 0);
  }
  return 0;
}

export const NO_ID = "NO_ID";

export function getLocation(pathname) {
  const pathVars = pathname.split("/").filter((el) => el); // filter for falsy values, such as empty string
  let location = [2, 4].reduce((loc, n) => {
    const pathIdTouple = pathVars.splice(0, n);
    if (pathIdTouple[0]) {
      loc[pathIdTouple[0]] = pathIdTouple[1] || NO_ID;
    }
    return loc;
  }, {});
  return location;
}

export async function createNewIdeaWithComment({ type, payload, comment }) {
  const { personId, projectId } = payload;
  const savedReference = await createEntity(type, payload);
  if (!savedReference) {
    throw new Error(`No ${type} returned`);
  } else {
    const commentPayload = {
      text: comment,
      onId: savedReference.id,
      authorId: personId,
    };
    const savedMessage = await createEntity("Comment", commentPayload);
    savedReference.comments = [savedMessage];
    savedReference["__typename"] = type;
    if (!savedMessage) {
      throw new Error("No Comment Added");
    }
    return savedReference;
    // TODO: dispatch(fetchTasksInProject({ personId, projectId }));
  }
}

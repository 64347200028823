export function isReactPlayerType(url) {
    const videos = ["youtube.com/watch", "youtu.be/", "vimeo.com", "wistia.com", "dailymotion.com", "twitch.tv"];
    let isPresent = false;
    videos.forEach((possibleWebsite) => {
        if(url.includes(possibleWebsite)) {
            isPresent = true;
        }
    });
    return isPresent;
}

export function isFacebookVideoLink(url) {
    return !!url.includes("facebook.com/") && !!url.includes("videos/");
}

export function isMixerVideoLink(url) {
    return !!url.includes("mixer.com/");
}

export function parseMixerLink(url) {
    let correctedUrl = "https://mixer.com/embed/player/";
    const splittedUrl = url.split("mixer.com/");
    if(splittedUrl.length === 1) return url;
    return correctedUrl + splittedUrl[1];
}

export function isVideoHandleably(videoUrl) {
    if(!videoUrl) return false;
    return isFacebookVideoLink(videoUrl) || isMixerVideoLink(videoUrl) || isReactPlayerType(videoUrl);
}

import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import assign from "lodash/assign";
import { getHighestComponentOrderAndGroupOrderForGroup } from "./orderingComponents";
import { objIsNotEmpty, handleError, notEmpty } from "../../utils/basic";
import {
  sortingComponentLabels,
  sortingGroupLabels,
} from "./orderingComponents";
import { sortByVersionConvertedToString } from "../../utils/helpers";
import { getCachedUrl } from "../../utils/StorageManager";
import { COMPONENT_UPLOADER_ROLE } from "../../content/componentsRules";
import { v4 as uuid } from "uuid";

export const getProgress = (progress) => {
  return Math.ceil((100 * progress.loaded) / progress.total);
};

export const setProgressBar = (progress, setUploadProgress) => {
  const uploadProgress = Math.ceil((100 * progress.loaded) / progress.total);
  setUploadProgress(uploadProgress);
};

export const isObjectWithoutFalsyValues = (payloads) => {
  for (const key in payloads) {
    const objWithFalsyValue = pickBy(payloads[key], (value) => value == null);
    if (!isEmpty(objWithFalsyValue)) {
      return false;
    }
  }
  return true;
};

export const onGetComponentGroupAndOrder = ({ group, componentsInGroup }) => {
  return getHighestComponentOrderAndGroupOrderForGroup(
    getOrderedComponents(componentsInGroup),
    group
  );
};

export const getOrderedComponents = (componentsWithLabels) => {
  if (isEmpty(componentsWithLabels)) return [];
  const orderedComponentsGroup = [];
  componentsWithLabels.forEach((componentsWithLabel) => {
    let updatedComponentWithLabel = {
      componentGroup: componentsWithLabel.componentGroup,
      componentOrderWeight: componentsWithLabel.componentOrderWeight,
      componentGroupOrderWeight: componentsWithLabel.componentGroupOrderWeight,
    };
    assign(
      updatedComponentWithLabel,
      get(componentsWithLabel, "component[0]", componentsWithLabel)
    );
    if (!isEmpty(updatedComponentWithLabel.versions)) {
      updatedComponentWithLabel.versions = updatedComponentWithLabel.versions
        .slice()
        .sort(sortByVersionConvertedToString);
    }
    const foundGroup = orderedComponentsGroup.find(
      (componentGroup) =>
        componentGroup.group === updatedComponentWithLabel.componentGroup
    );
    if (objIsNotEmpty(foundGroup)) {
      foundGroup.components.push(updatedComponentWithLabel);
    } else {
      const components = [updatedComponentWithLabel];
      orderedComponentsGroup.push({
        group: updatedComponentWithLabel.componentGroup,
        components,
      });
    }
  });
  orderedComponentsGroup.forEach((group) => {
    group.components.sort(sortingComponentLabels);
  });
  orderedComponentsGroup.sort(sortingGroupLabels);
  return orderedComponentsGroup;
};

export const buildPayloadAndMakeRequest = (
  state,
  accountId,
  projectId,
  personId,
  setPayloads,
  setCurrentFileUploaded,
  setUploadProgress
) => {
  const inputs = [];
  let {
    componentGroupOrderWeight,
    componentOrderWeight,
  } = onGetComponentGroupAndOrder(state.componentGroup);

  state.files.forEach((fileWrap) => {
    const metadata = {
      contentLength: fileWrap.file.size.toString(),
      lastModfified: fileWrap.file.lastModified.toString(),
    };
    const storageInput = {
      file: fileWrap.file,
      metadata,
      contentType: fileWrap.file.type,
      progressCallback: (progress) =>
        setProgressBar(progress, setUploadProgress),
    };
    const componentInput = {
      title: fileWrap.title && fileWrap.title.trim(),
      ...(fileWrap.isSourceFile && {
        software: fileWrap.software && fileWrap.software.trim(),
      }),
      version: "1",
    };
    const fileInput = {
      fileName: fileWrap.file.name,
      fileType: fileWrap.fileExtensionEnum,
      mimeType: fileWrap.file.type,
      size: fileWrap.file.size,
    };

    const payload = {
      accountId: accountId,
      partyId: personId,
      projectId,
      componentInput,
      fileInput,
      componentGroup: fileWrap.componentGroup.trim(),
      componentGroupOrderWeight,
      componentOrderWeight,
    };

    const input = {
      payload,
      sourceFile: fileWrap.isSourceFile,
      storageInput,
      fileInput,
    };

    if (isObjectWithoutFalsyValues(input)) {
      inputs.push(input);
    } else {
      handleError("Invalid input");
    }
  });

  setPayloads(inputs);
  setCurrentFileUploaded(inputs[0]);
};

export const buildComponentPayload = (
  state,
  projectId,
  personId,
  setPayloads,
  setCurrentFileUploaded,
  setUploadProgress
) => {
  if (state && state.files && state.files.length) {
    const inputs = [];
    let {
      componentGroupOrderWeight,
      componentOrderWeight,
    } = onGetComponentGroupAndOrder(state.componentGroup);

    state.files.forEach((fileWrap) => {
      const metadata = {
        contentLength: fileWrap.file.size.toString(),
        lastModfified: fileWrap.file.lastModified.toString(),
      };
      const storageInput = {
        file: fileWrap.file,
        metadata,
        contentType: fileWrap.file.type,
        progressCallback: (progress) =>
          setProgressBar(progress, setUploadProgress),
      };
      const componentInput = {
        title: fileWrap.title && fileWrap.title.trim(),
        ...(fileWrap.isSourceFile && {
          software: fileWrap.software && fileWrap.software.trim(),
        }),
        version: "1",
      };
      const fileInput = {
        fileName: fileWrap.file.name,
        fileType: fileWrap.fileExtensionEnum,
        mimeType: fileWrap.file.type,
        size: fileWrap.file.size,
      };

      const payload = {
        partyId: personId,
        projectId,
        componentInput,
        fileInput,
        componentGroup: fileWrap.componentGroup.trim(),
        componentGroupOrderWeight,
        componentOrderWeight,
      };

      const input = {
        payload,
        sourceFile: fileWrap.isSourceFile,
        storageInput,
        fileInput,
      };

      if (isObjectWithoutFalsyValues(input)) {
        inputs.push(input);
      } else {
        console.warn("Invalid input");
      }
    });

    setPayloads(inputs);
    setCurrentFileUploaded(inputs[0]);

    return true;
  }
};

export function getFileInput({
  uri,
  fileName,
  fileType,
  mimeType,
  size,
  bitDepth,
  samplingRate,
}) {
  return {
    uri,
    fileName,
    fileType,
    mimeType,
    size,
    bitDepth,
    samplingRate,
  };
}

export function getComponentInput(title, version, software) {
  return {
    id: uuid(),
    title,
    ...(software && { software }),
    version: `${version}`,
  };
}

export function getLastNumber(string) {
  if (!string) {
    return "";
  }
  return +new String(string).match(/\d+$/)[0];
}

export function computeComponentVersion(originalVersion) {
  const lastNumber = getLastNumber(originalVersion);
  return {
    getNewNested: `${originalVersion}-1`,
    increase: new String(originalVersion).replace(/\d+$/, +lastNumber + 1),
  };
}

export async function getStreamingLink(identityId, key) {
  try {
    const url = await getCachedUrl({ identityId, key });
    return url;
  } catch (e) {
    handleError("Error generation streaming link" + e);
  }
}

export function getlatestVersionsByProject(allComponents, personId, projectId) {
  function getLatestVersionUploadedByUser(component) {
    if (component.versions && component.versions.length) {
      const latestVersion = component.versions.find((c) => {
        // If a file is from a different project exclude it
        const componentProjectId = get(component, "project[0].id", "");
        const versionProjectId = get(c, "project[0].id", "");
        if (componentProjectId !== versionProjectId) return false;

        // must be uploaded by this user
        const uploaderContribution = (c.isContributionOf || []).find(
          (contr) => contr.role === COMPONENT_UPLOADER_ROLE
        );
        if (uploaderContribution) {
          const uploaderId = get(uploaderContribution, "contributor[0].id");
          return uploaderId && uploaderId === personId;
        }
        return false;
      });
      return latestVersion ? latestVersion : component;
    }
    return component;
  }

  const unassigned = [];

  let byProject = {};

  if (personId && allComponents) {
    byProject = allComponents.reduce((separatedByProject, c) => {
      const latest = getLatestVersionUploadedByUser(c);
      const latestProject = get(
        latest,
        "project[0]",
        get(latest, "referrer[0]")
      );
      const isNotInCurrentProject =
        latestProject && latestProject.id !== projectId;
      if (latest && isNotInCurrentProject) {
        if (latestProject && latestProject.isArchived)
          return separatedByProject;
        const projectName = latestProject && latestProject.title;
        if (projectName) {
          if (
            Object.prototype.hasOwnProperty.call(
              separatedByProject,
              projectName
            ) &&
            separatedByProject[projectName].length &&
            !separatedByProject[projectName].some((c) => c.id === latest.id)
          ) {
            separatedByProject[projectName].push(latest);
          } else {
            separatedByProject[projectName] = [latest];
          }
        } else {
          handleError(
            "There is a component without a project title in the import list, skipping it"
          );
        }
      } else if (!latestProject) {
        unassigned.push(latest);
      }

      return separatedByProject;
    }, {});
  }

  return {
    unassigned,
    byProject,
    length: unassigned.length + Object.values(byProject).length,
  };
}

export function getContributorsFromContributions(contributions) {
  return contributions.reduce((acc, current) => {
    const contributor = get(current, "contributor[0]", null);
    if (contributor) {
      acc.push(contributor);
    }
    return acc;
  }, []);
}

export async function getStreamAndPeakLinks({ identityId, key }) {
  try {
    const url = await getStreamingLink(identityId, key);

    const peaksLink = await getStreamingLink(identityId, key + ".json");

    const thumbnail = await getStreamingLink(identityId, key + ".jpeg");

    return { peaksLink, url, thumbnail };
  } catch (e) {
    handleError(e);
    handleError("Error generation download link");
  }
}

export function getUploader(component) {
  if (!component) return "";
  if (!notEmpty(component.isContributionOf)) return "";
  const uploaderContribution = component.isContributionOf.find(
    (contribution) => contribution.role === "uploader"
  );
  return get(uploaderContribution, "contributor[0]", null);
}

import { createEntity, readEntity, updateEntity } from "../../data/delicDataClient";
import { handleError } from "../../utils/basic";
import { isMixerVideoLink, parseMixerLink } from "../../components/PublicProfile/livestreamParser";
import { linkCleaner } from "../../components/References/referenceParser";
import { v4 as uuid } from "uuid";

export async function fetchPublicProfile(dispatch, { publicProfileId }) {
  try {
    //TODO we should at least check for the status code?
    const res = await readEntity("PublicProfile", { publicProfileId });
    dispatch({ type: "fetched", payload: { profile: res } })
  } catch (e) {
    handleError(e);
  }
}

export async function createPublicProfile(dispatch, { person, projectId, videoUrl, input, callBack }) {
  try {
    const res = await createEntity("PublicProfile", { partyId: person.id, input });
    if (res) {
      if (!projectId) {
        try {
          const newProjectId = uuid();
          let newProject = await createEntity("Project", {
            projectId: newProjectId,
            title: "My project",
            status: "New",
            partyId: person.id
          });
          if (newProject) {
            projectId = newProject.id;
          } else {
            handleError("Project Create Error");
          }
        } catch (e) {
          handleError(e);
        }
      }
      if (videoUrl) {
        await createLivestream(dispatch, person, videoUrl, projectId, res, callBack);
      } else {
        dispatch({ type: "fetch", payload: { accountId: person.accountId, callBack } });
      }
    } else {
      handleError("Error creating PublicProfile");
    }
  } catch (e) {
    handleError(e);
  }
}

export async function updatePublicProfile(dispatch, { person, projectId, videoUrl, input, callBack }) {
  try {
    const res = await updateEntity("PublicProfile", { partyId: person.id, input });
    if (res) {
      if (!projectId && videoUrl) {
        const newProjectId = uuid();
        let newProject = await createEntity("Project", {
          projectId: newProjectId,
          title: "My project",
          status: "New",
          partyId: person.id
        });
        if (newProject) {
          projectId = newProject.id;
        } else {
          handleError("Project Create Error");
        }
      }
      if (videoUrl) {
        await createLivestream(dispatch, person, videoUrl, projectId, res, callBack);
      } else {
        dispatch({ type: "fetch", payload: { accountId: person.accountId, callBack } });
      }
    } else {
      handleError("Error updating PublicProfile");
    }
  } catch (e) {
    handleError(e);
  }
}

async function createLivestream(dispatch, person, videoUrl, projectId, publicProfile, callBack) {
  try {
    let parsedUrl = videoUrl;
    if (isMixerVideoLink(videoUrl)) {
      parsedUrl = parseMixerLink(videoUrl);
    }
    parsedUrl = linkCleaner(parsedUrl);

    let newLiveStream = await createEntity("PublicLivestream", {
      partyId: person.id,
      profileId: publicProfile.id,
      projectId,
      url: parsedUrl
    });
    if (newLiveStream) {
      dispatch({ type: "fetch", payload: { accountId: person.accountId, callBack } });
    } else {
      handleError("Livestream Create Error");
    }
  } catch (e) {
    handleError(e);
  }
}

export async function setPublicProfileStatus(dispatch, { person, input, callBack }) {
  try {
    const res = await updateEntity("PublicProfile", { partyId: person.id, input });
    if (res) {
      dispatch({ type: "fetch", payload: { accountId: person.accountId, callBack } });
    } else {
      handleError("Error updating PublicProfile");
    }
  } catch (e) {
    handleError(e);
  }
}

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  readEntity,
  deleteEntity,
  updateEntity,
  createEntity,
  addRelationship,
  removeRelationship,
} from "../../data/delicDataClient";
import { tasksSchema, taskEntity } from "../schemas";
import { normalize } from "normalizr";
import {
  upsertCommentsFromCommentables,
  createCommentSuccess,
} from "../actions";
import { TaskStatus } from "../../components/Tasks/TaskStatus";
import debounce from "lodash/debounce";

export const tasksAdapter = createEntityAdapter();

const initialState = tasksAdapter.getInitialState({
  isLoading: false,
  error: false,
  byProjectId: {},
});

const tasks = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    onStart(state) {
      state.isLoading = true;
    },
    onFail(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    fetchTasksInProjectSuccess(state, { payload }) {
      const { tasks, projectId } = payload;
      const normalized = normalize({ tasks }, tasksSchema);
      tasksAdapter.upsertMany(state, normalized.entities.tasks);
      state.byProjectId[projectId] = normalized.result.tasks;
      state.isLoading = false;
      state.error = null;
    },
    addTaskInProjectSuccess(state, { payload }) {
      const { task, projectId } = payload;
      const normalized = normalize(task, taskEntity);
      tasksAdapter.addOne(state, normalized.entities.tasks[task.id]);
      state.byProjectId[projectId] = state.byProjectId[projectId]
        ? [normalized.result].concat(state.byProjectId[projectId])
        : [normalized.result];
      state.isLoading = false;
      state.error = null;
    },
    updateTaskInProjectSuccess(state, { payload }) {
      const { task } = payload;
      const normalized = normalize(task, taskEntity);
      tasksAdapter.upsertOne(state, normalized.entities.tasks[task.id]);
      state.error = null;
    },
    deleteTaskSuccess(state, { payload }) {
      const { taskId, projectId } = payload;
      tasksAdapter.removeOne(state, taskId);
      state.byProjectId[projectId] = state.byProjectId[projectId].filter(
        (id) => id !== taskId
      );
      state.isLoading = false;
      state.error = null;
    },
    addContributorSuccess(state, { payload }) {
      const { contributors, taskId } = payload;
      const task = state.entities[taskId];
      if (task && contributors) {
        task.contributor = contributors.map((c) => c.id);
        tasksAdapter.upsertOne(state, task);
      } else {
        throw new Error("No task with id: ", taskId);
      }
      state.isLoading = false;
      state.error = null;
    },
    addTagsToContributionSuccess(state, { payload }) {
      const { tags, taskId } = payload;
      const task = state.entities[taskId];
      if (task) {
        task.tags = task.tags.concat(tags);
        tasksAdapter.upsertOne(state, task);
      } else {
        throw new Error("No task with id: ", taskId);
      }
    },
    removeContributorSuccess(state, { payload }) {
      const { partyId, taskId } = payload;
      const task = state.entities[taskId];
      if (task && partyId) {
        task.contributor = task.contributor.filter((id) => id !== partyId);
        tasksAdapter.upsertOne(state, task);
      } else {
        throw new Error("No task with id: ", taskId);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCommentSuccess, (state, { payload }) => {
      const { comment, commentableId } = payload;

      if (state.entities[commentableId]) {
        state.entities[commentableId].comments = [comment.id].concat(
          state.entities[commentableId].comments
        );
      }
    });
  },
});

export const {
  selectById: selectTaskById,
  selectIds: selectTaskIds,
  selectEntities: selectTaskEntities,
  selectAll: selectAllTasks,
  selectTotal: selectTotalTasks,
} = tasksAdapter.getSelectors((state) => state.tasks);

export const selectTasksByProjectId = (projectId) =>
  createSelector(
    [
      (state) => state.tasks.entities,
      (state) => {
        return state.tasks.byProjectId[projectId] || [];
      },
    ],
    (tasks, ids) => {
      return ids && ids.map((id) => tasks[id]);
    }
  );

export const {
  onStart,
  onFail,
  fetchTasksInProjectSuccess,
  addTaskInProjectSuccess,
  updateTaskInProjectSuccess,
  deleteTaskSuccess,
  addContributorSuccess,
  removeContributorSuccess,
  addTagsToContributionSuccess,
} = tasks.actions;

export default tasks.reducer;

// vvvvvvvvvvv         THUNKS         vvvvvvvvvvv //

// ------------------- FETCH ------------------ //

export const fetchTasksInProject = ({ personId, projectId }) => async (
  dispatch
) => {
  try {
    dispatch(onStart());

    const res = await readEntity("AllTasksOfProject", {
      personId,
      projectId,
    });
    if (res) {
      dispatch(fetchTasksInProjectSuccess({ tasks: res.tasks, projectId }));
      dispatch(upsertCommentsFromCommentables(res.tasks));
    } else {
      dispatch(onFail("Error fetching AllTasksOfProject"));
    }
  } catch (e) {
    dispatch(onFail("Error fetching AllTasksOfProject: ", e.toString()));
  }
};

const nestedFetchTasksInProject = debounce(
  async (dispatch, { personId, projectId }) => {
    try {
      dispatch(onStart());

      const res = await readEntity("AllTasksOfProject", {
        personId,
        projectId,
      });
      if (res) {
        dispatch(fetchTasksInProjectSuccess({ tasks: res.tasks, projectId }));
        dispatch(upsertCommentsFromCommentables(res.tasks));
      } else {
        dispatch(onFail("Error fetching AllTasksOfProject"));
      }
    } catch (e) {
      dispatch(onFail("Error fetching AllTasksOfProject: ", e.toString()));
    }
  },
  500
);

export const fetchTasksInProjectDebounced = (...args) => (dispatch) =>
  nestedFetchTasksInProject(dispatch, ...args);

// ------------------- ADD ------------------ //

export const createTask = ({
  personId,
  projectId,
  title,
  description,
  dueDate,
  taskGroup,
  callBack,
}) => async (dispatch) => {
  try {
    dispatch(onStart());

    const optionalDate = dueDate ? { dueDate: { formatted: dueDate } } : null;
    const contributionInput = {
      title,
      description,
      ...optionalDate,
      taskGroup,
      status: TaskStatus.TO_DO,
    };
    const contribution = await createEntity("Contribution", {
      personId,
      projectId,
      input: contributionInput,
    });
    if (contribution) {
      dispatch(addTaskInProjectSuccess({ task: contribution, projectId }));
      callBack();
    } else {
      dispatch(onFail("Error adding task"));
    }
  } catch (e) {
    dispatch(onFail("Error adding task: ", e.toString()));
  }
};

// ------------------- UPDATE ------------------ //

export const updateTask = ({ personId, projectId, input }) => async (
  dispatch
) => {
  try {
    const updatedContribution = await updateEntity("Contribution", {
      personId,
      projectId,
      input,
    });
    if (!updatedContribution) {
      dispatch(onFail("Error completing task"));
    } else {
      dispatch(
        updateTaskInProjectSuccess({ task: updatedContribution, projectId })
      );
    }
  } catch (e) {
    dispatch(onFail("Error completing task: ", e.toString()));
  }
};

const nestedUpdateTask = debounce(
  async (dispatch, { personId, projectId, input, callback = () => {} }) => {
    try {
      const updatedContribution = await updateEntity("Contribution", {
        personId,
        projectId,
        input,
      });
      if (!updatedContribution) {
        dispatch(onFail("Error completing task"));
      } else {
        dispatch(
          updateTaskInProjectSuccess({ task: updatedContribution, projectId })
        );
      }
      callback();
    } catch (e) {
      callback();
      dispatch(onFail("Error completing task: ", e.toString()));
    }
  },
  1000
);

export const updateTaskDebounced = (...args) => (dispatch) =>
  nestedUpdateTask(dispatch, ...args);

export const updateGroupName = ({
  personId,
  projectId,
  taskIds,
  newGroupName,
}) => async (dispatch) => {
  try {
    const payload = {
      personId: personId,
      projectId,
      taskIds,
      groupName: newGroupName,
    };
    const task = await updateEntity("TaskGroup", payload);
    if (!task) {
      dispatch(onFail("Couldn't update task group"));
    } else {
      dispatch(updateTaskInProjectSuccess({ task, projectId }));
    }
  } catch (e) {
    dispatch(onFail(e));
  }
};

export const addContributorsToContribution = (
  { contributionId, personIds },
  callBack
) => async (dispatch) => {
  try {
    const contributors = await addRelationship(
      "ResetContributionContributors",
      { contributionId, personIds }
    );
    if (contributors) {
      dispatch(
        onFail("error fetching AddOrRemoveMultipleContributorsToContribution")
      );
      dispatch(addContributorSuccess({ contributors, taskId: contributionId }));
      if (callBack) {
        callBack();
      }
    } else {
      dispatch(onFail("Could not add contributor"));
    }
  } catch (e) {
    dispatch(onFail(e));
  }
};

export const addTagsToContributionDebounced = (...args) => (dispatch) =>
  nestedAddTagsToContribution(dispatch, ...args);
const nestedAddTagsToContribution = debounce(
  async (dispatch, { contributionId, tagNames }, callBack) => {
    try {
      const tags = await addRelationship("ResetTagsToContribution", {
        contributionId,
        tagNames,
      });
      if (!tags) {
        dispatch(onFail("error fetching ResetTagsToContribution"));
      } else {
        dispatch(
          addTagsToContributionSuccess({ tags, taskId: contributionId })
        );
        if (callBack) {
          callBack();
        }
      }
    } catch (e) {
      dispatch(onFail(e));
    }
  },
  1000
);

export const removeContributorFromTask = ({
  partyId,
  contributionId,
}) => async (dispatch) => {
  try {
    const contribution = await removeRelationship(
      "RemoveContributionContributor",
      { contributionId, partyId }
    );
    if (contribution == null) {
      dispatch(onFail("Couldn't remove contributor"));
    } else {
      dispatch(removeContributorSuccess({ partyId, taskId: contributionId }));
    }
  } catch (e) {
    dispatch(onFail(e));
  }
};

// ------------------- DELETE ------------------ //

export const deleteTask = ({ personId, projectId, id }) => async (dispatch) => {
  try {
    const payload = {
      personId,
      projectId,
      contributionId: id,
    };
    const deletedContribution = await deleteEntity("Contribution", payload);
    if (!deletedContribution || !deletedContribution.id) {
      dispatch(onFail("Delete Task Error: No DeleteContribution ID returned"));
    } else {
      dispatch(
        deleteTaskSuccess({ taskId: deletedContribution.id, projectId })
      );
    }
  } catch (e) {
    dispatch(onFail(e));
  }
};

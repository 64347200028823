import { schema } from "normalizr";

// definite normalizr entity schemas
export const memberEntity = new schema.Entity("members");
export const commentEntity = new schema.Entity("comments", {
  author: memberEntity,
});

export const projectEntity = new schema.Entity("project", {
  members: [memberEntity],
  owners: [memberEntity],
});

export const taskEntity = new schema.Entity("tasks", {
  contributor: [memberEntity],
  comments: [commentEntity],
});

export const tasksSchema = { tasks: [taskEntity] };
export const commentsSchema = { comments: [commentEntity] };

export const contributionEntity = {
  contributor: [memberEntity],
};

export const componentVersionEntity = new schema.Entity("componentVersions", {
  contributions: [contributionEntity],
  isContributionOf: [contributionEntity],
  comments: [commentEntity],
  project: [projectEntity],
  contributors: [memberEntity],
});

export const componentEntity = new schema.Entity("components", {
  contributors: [memberEntity],
  isContributionOf: [contributionEntity],
  comments: [commentEntity],
  versions: [componentVersionEntity],
  project: [projectEntity],
});

export const groupedComponentEntity = {
  component: [componentEntity],
};

export const componentsSchema = {
  components: [groupedComponentEntity],
};

export const contributionSchema = {
  contributor: [memberEntity],
};

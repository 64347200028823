export const groups = {
  DAW_INTERCHANGE: "DAW Interchange Files",
  AUDIO_FILE: "Audio",
  VIDEO_FILE: "Video",
  IMAGE_FILE: "Image",
  MIDI_FILE: "MIDI",
  OSC_FILE: "OSC",
  SAMPLE_FILE: "Samples",
  DAW_PROJECT_FILE: "DAW Project Files",
  PLUGIN_FILE: "Plugins",
  CONTAINER_FILE: "Zip Files",
  GENERIC_FILE: "General Files"
};

export const software = {
  GENERIC: "Generic",
  ABLETON: "Ableton",
  CUBASE: "Cubase",
  GARAGE_BAND: "Garage Band",
  LOGIC: "Logic",
  PROTOOLS: "Protools",
  REAPER: "Reaper",
}

export const fileFormatGroups = {
  ".omf": {
    group: groups.DAW_INTERCHANGE,
    software: software.GENERIC
  },
  ".aaf": {
    group: groups.DAW_INTERCHANGE,
    software: software.GENERIC
  },
  ".aac": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".ac3": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".aif": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".aiff": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".mp3": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".m4a": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".caf": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".pcm": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".wav": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".ogg": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".flac": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".opus": {
    group: groups.AUDIO_FILE,
    software: software.GENERIC
  },
  ".mid": {
    group: groups.MIDI_FILE,
    software: software.GENERIC
  },
  ".osc": {
    group: groups.OSC_FILE,
    software: software.GENERIC
  },
  ".sbk": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".sb2": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".sf2": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".exs": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".nki": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".nks": {
    group: groups.SAMPLE_FILE,
    software: software.GENERIC
  },
  ".pts": {
    group: groups.DAW_PROJECT_FILE,
    software: software.PROTOOLS
  },
  ".logicx": {
    group: groups.DAW_PROJECT_FILE,
    software: software.LOGIC
  },
  ".logicx.zip": {
    group: groups.DAW_PROJECT_FILE,
    software: software.LOGIC
  },
  ".cpr": {
    group: groups.DAW_PROJECT_FILE,
    software: software.CUBASE
  },
  ".npr": {
    group: groups.DAW_PROJECT_FILE,
    software: software.CUBASE
  },
  ".als": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".asd": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".ask": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".adg": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".alp": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".adv": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".adp": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".alc": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".agr": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".ams": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".amxd": {
    group: groups.DAW_PROJECT_FILE,
    software: software.ABLETON
  },
  ".band": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GARAGE_BAND
  },
  ".band.zip": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GARAGE_BAND
  },
  ".pd": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GENERIC
  },
  ".scd": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GENERIC
  },
  ".vcv": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GENERIC
  },
  ".maxpat": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GENERIC
  },
  ".rpprj": {
    group: groups.DAW_PROJECT_FILE,
    software: software.REAPER
  },
  ".rpp": {
    group: groups.DAW_PROJECT_FILE,
    software: software.REAPER
  },
  ".ptx": {
    group: groups.DAW_PROJECT_FILE,
    software: software.PROTOOLS
  },
  ".ptf": {
    group: groups.DAW_PROJECT_FILE,
    software: software.GENERIC
  },
  ".component": {
    group: groups.PLUGIN_FILE,
    software: software.GENERIC
  },
  ".au": {
    group: groups.PLUGIN_FILE,
    software: software.GENERIC
  },
  ".vst": {
    group: groups.PLUGIN_FILE,
    software: software.GENERIC
  },
  ".tar": {
    group: groups.CONTAINER_FILE,
    software: software.GENERIC
  },
  ".tar.gz": {
    group: groups.CONTAINER_FILE,
    software: software.GENERIC
  },
  ".zip": {
    group: groups.CONTAINER_FILE,
    software: software.GENERIC
  },
  ".zipx": {
    group: groups.CONTAINER_FILE,
    software: software.GENERIC
  },
};

export const zippableSoftwareFolders = [
  ".logicx",
  ".band"
];

export const zippableSoftwareExtentions = [
  ".als",
  ".cpr",
  ".npr",
  ".ptx",
  ".pts",
  ".ptf",
  ".rpp"
];

import React, { useEffect, useState } from "react";
import { Hub } from '@aws-amplify/core';
import Auth from "@aws-amplify/auth";
import { SimpleDelicLoader } from "delic-ui";
import {handleError} from "../utils/basic";
export const AuthContext = React.createContext();


const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        async function checkUserRefreshPage(bypassCache = false) {
            try {
                const id = await getIdentityId();
                const user = await Auth.currentAuthenticatedUser({bypassCache: bypassCache})

                setUser({ ...user, id });
                setIsLoaded(true)
            } catch (e) {
                handleError(e)
                setUser(null);
                setIsLoaded(true)
            }
        }

        async function getIdentityId() {
            let credentials = await Auth.currentCredentials();
            return credentials.identityId
        }

        async function hubListener({ payload }) {
            const { event, data } = payload

            switch (event) {
                case 'signIn': {
                    const id = await getIdentityId();
                    setUser({ ...data, id });
                    setIsLoaded(true);
                    break;
                }
                case 'signOut':
                    setUser(null);
                    setIsLoaded(true);
                    break;
                default: break;
            }
        }

        async function hubDelicListener({ payload }) {
            const { event } = payload

            switch (event) {
                case 'updateUser': {
                    checkUserRefreshPage(true);
                    break;
                }
                default: break;
            }
        }
        Hub.listen('auth', hubListener);
        Hub.listen('delic', hubDelicListener);

        checkUserRefreshPage(true);

        return (() => {
            Hub.remove('auth', hubListener)
            Hub.remove('delic', hubDelicListener)
        })
    }, [])

    if (!isLoaded) {
        return <SimpleDelicLoader />;
    }

    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }

import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import componentsReducer from "./redux/slices/componentsSlice";
import projectsReducer from "./redux/slices/projectsSlice";
import membersReducer from "./redux/slices/projectMembers";
import transferManager from "./redux/slices/transferManagerSlice";
import tasksReducer from "./redux/slices/tasksSlice";
import commentsReducer from "./redux/slices/commentsSlice";
import references from "./redux/slices/referencesSlice";

const createAppReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    projects: projectsReducer,
    components: componentsReducer,
    members: membersReducer,
    transferManager: transferManager,
    comments: commentsReducer,
    tasks: tasksReducer,
    references: references,
  });

const createRootReducer = (history) => (state, action) => {
  // reset the state of a redux store
  if (action.type === "resetStore") {
    return undefined;
  }
  return createAppReducer(history)(state, action);
};

export default createRootReducer;

import {createEntity, deleteEntity, updateEntity} from "../../data/delicDataClient";
import {handleError} from "../../utils/basic";
import {isMixerVideoLink, parseMixerLink} from "../../components/PublicProfile/livestreamParser";
import {linkCleaner} from "../../components/References/referenceParser";

export async function createLivestream(dispatch, {person, projectId, videoUrl, callBack}) {
    try {
        let parsedUrl = videoUrl;
        if (isMixerVideoLink(videoUrl)) {
            parsedUrl = parseMixerLink(videoUrl);
        }
        parsedUrl = linkCleaner(parsedUrl);
        let newLiveStream = await createEntity("Livestream", {
            partyId: person.id,
            projectId,
            url: parsedUrl
        });
        if(newLiveStream) {
            dispatch({type: "fetch", payload: {accountId: person.accountId, callBack}});
        } else {
            handleError("Livestream Create Error");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function deleteLivestream(dispatch, {person, projectId, videoId, callBack}) {
    try {
        let newLiveStream = await deleteEntity("Livestream", {
            partyId: person.id,
            projectId,
            videoId
        });
        if(newLiveStream) {
            dispatch({type: "fetch", payload: {accountId: person.accountId, callBack}});
        } else {
            handleError("Livestream Delete Error");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function unpublishLivestream(dispatch, {person, profileId, videoId, callBack}) {
    try {
        let unpublishedVideo = await updateEntity("UnpublishVideo", {
            partyId: person.id,
            profileId,
            videoId
        });
        if(unpublishedVideo) {
            dispatch({type: "fetch", payload: {accountId: person.accountId, callBack}});
        } else {
            handleError("Livestream unpublish Error");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function publishLivestream(dispatch, {person, profileId, videoId, callBack}) {
    try {
        let publishedVideo = await updateEntity("PublishVideo", {
            partyId: person.id,
            profileId,
            videoId
        });
        if(publishedVideo) {
            dispatch({type: "fetch", payload: {accountId: person.accountId, callBack}});
        } else {
            handleError("Livestream publish Error");
        }
    } catch (e) {
        handleError(e);
    }
}

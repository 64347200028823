import { withTryCatch } from "../../utils/dispatchersHelpers";
import { curriedReadEntity } from "../../data/delicDataClient";
import { statisticsDispatchActions } from "./StatisticsDispatch";

export async function fetchBasicStatistics(statisticsDispatch) {
  withTryCatch(
    async () => {
      const res = await getBasicStatistics({
        excludedImgType: "PROFILE_PICTURE",
        completedTaskStatus: "Done"
      });
      if (res) {
        statisticsDispatch({
          type: statisticsDispatchActions.FETCHED,
          payload: res
        });
      } else {
        throw new Error("Couldn't update component file");
      }
    },
    "Couldn't update component file",
    statisticsDispatch
  );
}

async function getBasicStatistics(payload) {
  return await curriedReadEntity("BasicStatistics")(payload);
}

import React, { useCallback, useReducer, useEffect, useState } from "react";
import { acceptTermsAndConditions, fetchPerson, getFounderMemberStatus, updatePerson } from "./personService";
import { createEntity, readEntity, updateEntity } from "../../data/delicDataClient";
import { useAuth } from "../../context/AuthContext";
import { useLocation, useHistory } from "react-router-dom";
import { signOut } from "../../utils/signOut";
import { SimpleDelicLoader } from "delic-ui";
import { AUTH_STATUS, INVITATION_STATUS } from "../../content/invitationsContent";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { redirectToComponent } from "../../utils/redirect";

const PersonStateContext = React.createContext();
const PersonDispatchContext = React.createContext();

const PersonProvider = ({ children }) => {
  const user = useAuth();
  const { state } = useLocation();
  const history = useHistory();

  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case "update":
        updatePerson(personDispatch, action.payload);
        return state;
      case "acceptTermsAndConditions":
        acceptTermsAndConditions(personDispatch, action.payload, state);
        return state;
      case "fetch":
        fetchPerson(personDispatch, action.payload);
        return state;
      case "fetched":
        return action.payload;
      case "getFounderMemberStatus":
        getFounderMemberStatus(action.payload);
        return state;
      default:
        return state;
    }
  }, []);

  const [person, personDispatch] = useReducer(reducer, null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    function getInvitationData() {
      let invitationData = state ? state.invitationData : null;
      if (invitationData) return invitationData;
      invitationData = sessionStorage.getItem("invitationData");
      if (invitationData) {
        sessionStorage.removeItem("invitationData");
        try {
          return JSON.parse(invitationData);
        } catch (e) {
          console.warn(e);
        }
      }
    }

    function getRedirectData() {
      let redirectData = state ? state.redirectData : null;
      if (redirectData) return redirectData;
      redirectData = sessionStorage.getItem("redirectData");
      if (redirectData) {
        sessionStorage.removeItem("redirectData");
        try {
          return JSON.parse(redirectData);
        } catch (e) {
          console.warn(e);
        }
      }
    }

    function handleRedirect(redirectData, invitationData) {
      const projectId = redirectData.projectId;
      if (projectId) {
        history.push({
          pathname: projectId ? `/project/${projectId}/${redirectData.targetType}/${redirectData.targetId}` : "/",
        });
      } else {
        const path = redirectData.path ? redirectData.path : "/";
        history.push(path, { ...redirectData, invitationData });
      }
    }

    async function _getPerson({ cognitoUser, invitationData, redirectData }) {
      let person;
      try {
        const user = getUserData(cognitoUser);

        if (!user) {
          throw new Error("Error: Cognito User: ", cognitoUser);
        }

        person = await readEntity("PersonByEmail", { email: user.email });

        if (person) {
          if (person.accountId !== user.accountId && user.accountId != null) {
            // TODO: do we still need this?
            person = await migratePersonAccountId({
              newAccountId: user.accountId,
              oldAccountId: person.accountId,
              personId: person.id,
            });
          }
        } else {
          person = await _createPerson(user);
        }

        if (!person) {
          throw new Error("Invalid person");
        }

        if (
          invitationData &&
          invitationData.personId &&
          invitationData.projectId &&
          invitationData.status !== "ACCEPTED"
        ) {
          await tryToUpdateInvitedPerson(cognitoUser, person, invitationData);
        }

        personDispatch({ type: "fetched", payload: person });
        if (invitationData) {
          redirectToComponent({
            projectId: get(invitationData, "projectId"),
            componentId: get(invitationData, "componentId", ""),
            history,
          });
        }

        if (redirectData) {
          handleRedirect(redirectData, invitationData);
        }
      } catch (e) {
        if (process.env.NODE_ENV !== "development") {
          signOut();
        }
        console.warn("Person Dispatch error: ", e);
      } finally {
        setIsFetching(false);
      }
    }

    if (user && !person && history && !isFetching) {
      const invitationData = getInvitationData();
      const redirectData = getRedirectData();

      setIsFetching(true);
      _getPerson({ cognitoUser: user, invitationData, redirectData });
    }
  }, [person, user, state, history, isFetching]);

  if (!person) {
    return <SimpleDelicLoader />;
  }

  return (
    <PersonStateContext.Provider value={person}>
      <PersonDispatchContext.Provider value={personDispatch}>{children}</PersonDispatchContext.Provider>
    </PersonStateContext.Provider>
  );
};

function isPersonInProject(person, projectId) {
  const projectsAssets = get(person, "assets", []).filter(
    (asset) => asset["__typename"] === "Project"
  );
  const projects = [
    ...projectsAssets,
    ...get(person, "projectsMembership", []),
  ];
  return !!projects.find((proj) => proj.id === projectId);
}

export async function tryToUpdateInvitedPerson(user, person, invitationData) {
  const cleanedUser = getUserData(user);
  if (!isPersonInProject(person, invitationData.projectId)) {
    person = await updateInvitedPerson({
      ...cleanedUser,
      invitationData,
      personId: person.id,
    });
    if (!person) {
      throw new Error("Couldn't update invited person");
    }
  }
}

async function updateInvitedPerson({
  name,
  accountId,
  email,
  invitationData,
  personId,
}) {
  const personInput = validatePersonInput({
    id: personId,
    fullName: name,
    accountId,
    emailAddress: email,
    authStatus: AUTH_STATUS.CONFIRMED,
    acceptedDelicTandCs: false,
  });

  const invitationInput = {
    status: INVITATION_STATUS.ACCEPTED,
  };
  const input = {
    personInput,
    invitationInput,
    projectId: invitationData.projectId,
    currentInvitationStatus: INVITATION_STATUS.PENDING,
    invitationId: invitationData.invitationId,
    inviteeId: invitationData.personId,
    invitationIsArchived: false,
  };

  return await updateEntity("InvitedPerson", input);
}

function _createPerson({ name, accountId, email }) {
  const person = validatePersonInput({
    fullName: name,
    accountId,
    emailAddress: email,
    authStatus: AUTH_STATUS.CONFIRMED,
    acceptedDelicTandCs: false,
  });
  return createEntity("Person", { person });
}

function validatePersonInput({
  id,
  fullName,
  accountId,
  emailAddress,
  authStatus,
  acceptedDelicTandCs,
}) {
  if (
    !fullName ||
    !accountId ||
    !emailAddress ||
    !authStatus ||
    acceptedDelicTandCs !== false
  ) {
    throw new Error(
      "Cannot validate person input: " + id,
      fullName,
      accountId,
      emailAddress,
      authStatus,
      acceptedDelicTandCs
    );
  }
  return {
    id,
    fullName,
    accountId,
    emailAddress,
    authStatus,
    acceptedDelicTandCs,
  };
}

async function migratePersonAccountId({
  newAccountId,
  oldAccountId,
  personId,
}) {
  //TODO make sense to have an error if the legacy account is already filled?
  const personByEmail = await updateEntity("MigratePersonAccountId", {
    personId,
    oldAccountId,
    newAccountId,
  });
  if (isEmpty(personByEmail)) {
    throw new Error("couldn't migrate account Id");
  }
  return personByEmail;
}

function getUserData(user) {
  const data = {
    email: user.email
      ? user.email
      : get(user, "signInUserSession.idToken.payload.email"),
    accountId: user.id,
    name: user.name
      ? user.name
      : get(user, "signInUserSession.idToken.payload.name", ""),
  };
  if (!Object.values(data).some((el) => el === undefined)) {
    return data;
  }
}

function usePersonState(breakIfContextIsMissing = true) {
  const context = React.useContext(PersonStateContext);
  if (breakIfContextIsMissing && context === undefined) {
    throw new Error("usePersonState must be used within a PersonProvider");
  }
  return context;
}

function usePersonDispatch() {
  const context = React.useContext(PersonDispatchContext);
  if (context === undefined) {
    throw new Error("usePersonDispatch must be used within a PersonProvider");
  }
  return context;
}

export { PersonProvider, usePersonState, usePersonDispatch };

import Storage from '@aws-amplify/storage'
import Cache from '@aws-amplify/cache';
import { v4 as uuid } from 'uuid';

const EXPIRE_IN_SECONDS = 3600

// supported response headers https://github.com/aws-amplify/amplify-js/pull/4323
export function getProtectedFile({ identityId, key, expires, contentDisposition }) {
    if (identityId && key) {
        return Storage.get(key, {
            level: 'protected',
            identityId,
            expires,
            contentDisposition
        })
    }
    return ""
}

function getProtectedFileWithExpiration({ identityId, key }) {
    return getProtectedFile({ identityId, key, expires: EXPIRE_IN_SECONDS })
}

export function downloadProtectedFile({ identityId, key }) {
    return getProtectedFile({ identityId, key, contentDisposition: 'attachment' })
}

function putProtectedFile(fileName, fileData, metadata, contentType, progressCallback) {
    return Storage.put(
        fileName,
        fileData,
        {
            level: 'protected',
            contentType,
            metadata,
            progressCallback
        }
    )
}

export async function getCachedUrl({ identityId, key }) {

    if (!key) {
        return ""
    }

    const cachedImage = Cache.getItem(key)
    if (cachedImage) {
        return cachedImage
    }
    const url = await getProtectedFileWithExpiration({ identityId, key })
    const expires = new Date().getTime() + (EXPIRE_IN_SECONDS * 960); // expire 2 mins before the url expires
    Cache.setItem(key, url, { expires })

    return url
}

export async function getProfilePicUrl({ identityId, key }) {
    return await getCachedUrl({ identityId, key })
}

export function uploadProfilePicture({ fileData, metadata, contentType, progressCallback }) {
    const id = uuid();
    const fileName = 'upload/' + id;
    return putProtectedFile(fileName, fileData, { ...metadata, id }, contentType, progressCallback)
}

export function uploadAudioFile({ file, metadata, contentType, progressCallback }, componentId) {
    const fileNameToFormat = file.name.trim().replace(/\//ig, "");
    const fileName = 'audio/' + componentId + "/" + fileNameToFormat;
    return putProtectedFile(fileName, file, metadata, contentType, progressCallback);
}

export function uploadFile({ file, metadata, contentType, progressCallback }, componentId) {
    const fileNameToFormat = file.name.trim().replace(/\//ig, "");
    const fileName = 'file/' + componentId + "/" + fileNameToFormat;
    return putProtectedFile(fileName, file, metadata, contentType, progressCallback);
}

export async function uploadFileToS3({ file, metadata, contentType }, progressCallback, isSourceFile) {
    const fileNameToFormat = file.name.trim().replace(/\//ig, "");
    // const fileNameToFormatCleaned = fileNameToFormat.replace(/[^a-zA-Z0-9]/g, '');
    const folder = isSourceFile ? "file/" : "audio/";
    const fileFolderForUniqueness = uuid();
    const fileName = folder + fileFolderForUniqueness + "/" + fileNameToFormat;
    return putProtectedFile(fileName, file, metadata, contentType, progressCallback);
}

export function removeFileToS3(fileKey, identityId) {
    return Storage.remove(
        fileKey,
        {
            level: 'protected',
            identityId
        }
    )
}
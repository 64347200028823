import { getQueryMap, createQueryMap, updateQueryMap, deleteQueryMap, addRelationshipQueryMap, removeRelationshipQueryMap, searchQueryMap } from 'delic-data-queries'
import API from '@aws-amplify/api';

const makeRequest = async (type, data, query) => {
    const graphqlOperation = {
        query: query[type],
        variables: data,
        authMode: 'AWS_IAM'
    };
    let response = await API.graphql(graphqlOperation);
    const keys = Object.keys(response.data)
    if (keys.length > 1) {
        const isTruthy = Object.values(response.data).filter(o => !!o).length
        return isTruthy
    }
    return response.data[keys[0]];
};

export const createEntity = async (type, data) => makeRequest(type, data, createQueryMap);

export const readEntity = async (type, data) => makeRequest(type, data, getQueryMap);

export const updateEntity = async (type, data) => makeRequest(type, data, updateQueryMap);

export const deleteEntity = async (type, data) => makeRequest(type, data, deleteQueryMap);

export const searchEntity = async (type, query) => makeRequest(type, query, searchQueryMap);

export const addRelationship = async (relationship, data) => makeRequest(relationship, data, addRelationshipQueryMap);

export const removeRelationship = async (relationship, data) => makeRequest(relationship, data, removeRelationshipQueryMap);

export function curriedDeleteEntity(mutationType) {
    return function (payload) {
        return deleteEntity(mutationType, payload)
    }
}

export function curriedUpdateEntity(mutationType) {
    return function (payload) {
        return updateEntity(mutationType, payload)
    }
}

export function curriedCreateEntity(mutationType) {
    return function (payload) {
        return createEntity(mutationType, payload)
    }
}
export function curriedReadEntity(mutationType) {
    return function (payload) {
        return readEntity(mutationType, payload)
    }
}

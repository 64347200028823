import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import 'delic-ui/dist/index.css';
import aws_exports from "./aws-exports";
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth'
import { Provider } from 'react-redux'
import store, { history } from './store'
import { ConnectedRouter } from 'connected-react-router/immutable'

// ENABLE DEBUG MODE
// window.LOG_LEVEL = "DEBUG";

// This is needed so that we can google login from localhost https://github.com/aws-amplify/amplify-cli/issues/2792
if (process.env.NODE_ENV === "development") {
    aws_exports.oauth.redirectSignIn = `${window.location.origin}/`;
    aws_exports.oauth.redirectSignOut = `${window.location.origin}/`;

    // Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

aws_exports.oauth.domain = process.env.REACT_APP_OAUTH_DOMAIN;

Amplify.configure(aws_exports);
Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_DELIC_DATA_ENDPOINT,
        graphql_endpoint_iam_region: 'eu-west-1',
        endpoints: [
            {
                name: "DelicPaymentAPI",
                endpoint: process.env.REACT_APP_DELIC_PAYMENT_API
            },
        ]
    }
})
Auth.configure(aws_exports)

const render = () => {
    const App = require('./App').default

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
            <App />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

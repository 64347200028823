export const TaskStatus = {
    TO_DO: "To Do",
    IN_PROGRESS: "In Progress",
    DONE: "Done"
};

export const getStatusDropdownColor = (status) => {
    switch (status) {
        case TaskStatus.TO_DO: return "purple";
        case TaskStatus.IN_PROGRESS: return "green";
        case TaskStatus.DONE: return "blue";
        default: return "blue"

    }
};

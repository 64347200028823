import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { handleError } from "../../utils/basic";
import { readEntity } from "../../data/delicDataClient";
import { onConfirmTransferSuccess } from "./transferManagerSlice";

export const referencesAdapter = createEntityAdapter();

const initialState = referencesAdapter.getInitialState({
  isLoading: true,
  error: "",
  references: [],
});

const references = createSlice({
  name: "references",
  initialState,
  reducers: {
    getLatestReferencesStart(state) {
      state.isLoading = false;
    },
    getLatestReferencesSuccess(state, { payload }) {
      const { references } = payload;
      state.references = references;
      state.latestError = null;
      state.isLoading = false;
    },
    getLatestReferencesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    upsertReference(state, { payload }) {
      const { reference } = payload;
      state.references = state.references.map((r) =>
        r.id === reference.id ? reference : r
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onConfirmTransferSuccess, (state, { payload }) => {
      const { idea, references } = payload;
      if (references) {
        state.references = state.references.concat(references);
      }
      if (idea) {
        state.references = state.references.concat([idea]);
      }
    });
  },
});

export const {
  getLatestReferencesStart,
  getLatestReferencesSuccess,
  getLatestReferencesFailure,
  upsertReference,
} = references.actions;

export default references.reducer;

// THUNKS //

export const fetchReferences = ({ personId, projectId }) => async (
  dispatch
) => {
  try {
    dispatch(getLatestReferencesStart());
    const res = await readEntity("AllReferencesInProject", {
      personId,
      projectId,
    });
    if (res) {
      dispatch(getLatestReferencesSuccess({ references: res.references }));
    } else {
      throw new Error("Couldn't read references in the project");
    }
  } catch (e) {
    handleError(e);
    dispatch(getLatestReferencesFailure(e.message));
  }
};

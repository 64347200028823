import React, { useCallback, useReducer } from "react";
import {} from "./statisticsService";
import { fetchBasicStatistics } from "./statisticsService";

export const statisticsDispatchActions = {
  BASIC_STATISTICS: "basicStatistics",
  FETCHED: "fetched"
};

export const StatisticsDispatch = React.createContext(null);

const initialState = {};

export const StatisticsDispatcher = ({ children }) => {
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case statisticsDispatchActions.BASIC_STATISTICS:
        fetchBasicStatistics(statisticsDispatch);
        return state;
      case statisticsDispatchActions.FETCHED:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }, []);

  const [statistics, statisticsDispatch] = useReducer(reducer, initialState);

  return (
    <StatisticsDispatch.Provider
      value={{
        statistics,
        statisticsDispatch
      }}
    >
      {children}
    </StatisticsDispatch.Provider>
  );
};

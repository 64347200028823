import {Link} from "react-router-dom";
import React from "react";

export const INVITATION_PLACEHOLDER_MESSAGE_FOR_INVITE = "Create a message that will be seen by your collaborator when they click on the link (optional)";

export const EMAIL_INVITE = {
  subject: "Invitation to use Streamline Media",
  body: `I'm inviting you to join a project in Streamline Media.\n\nUse this link to sign up. I'll see you there!:`
};

export const INVITATION_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REMOVED: "REMOVED",
  LEFT: "LEFT"
};

export const AUTH_STATUS = {
  INVITED: "INVITED",
  CONFIRMED: 'CONFIRMED'
};

export const INVITATION_DEFAULT_MESSAGE = "To work on the project, hit the blue button below.";
export const INVITATION_WITH_COMPONENT_DEFAULT_MESSAGE = "You can preview or download the file from here. To work on it, hit the blue button below.";

export const INVITATION_CLAIMED_ERROR = (
  <>
    The invitation has been already claimed, you can
    <Link to="/signup"> create an account </Link>
     and then notify the person that sent you the link
  </>);

export const INVITATION_NOT_VALID_ERROR = (
  <>
    The invitation is not valid, you can
    <Link to="/signup"> create an account </Link>
     and then notify the person that sent you the link
  </>);

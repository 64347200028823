
import { PublicProfileDataDispatcher } from "./publicProfile/PublicProfileDispatch";
import { PersonProvider as PersonDataDispatcher } from "./person/PersonDispatch";
import { LivestreamDispatcher } from "./livestream/LivestreamDispatch";
import { ReferencesDispatcher } from "./references/ReferencesDispatch";
import { PrivateLivesDispatcher } from "./privateLives/PrivateLivesDispatch";
import { StatisticsDispatcher } from "./statistics/StatisticsDispatch";
import { NavigationDispatcher } from "./navigation/NavigationDispatch";

export default {
  PublicProfileDataDispatcher,
  PersonDataDispatcher,
  LivestreamDispatcher,
  ReferencesDispatcher,
  PrivateLivesDispatcher,
  StatisticsDispatcher,
  NavigationDispatcher
}


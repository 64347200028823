import React, { useCallback, useReducer } from "react";
import {
  createPublicProfile,
  fetchPublicProfile,
  setPublicProfileStatus,
  updatePublicProfile
} from "./publicProfileService";
import { usePersonDispatch } from "../person/PersonDispatch";

export const PUBLIC_PROFILE_DISPATCH_ACTIONS = {
  ADD: "add",
  UPDATE: "update",
  SETSTATUS: "setStatus",
  FETCH: "fetch",
  FETCHED: "fetched"
}

export const PublicProfileDispatch = React.createContext(null);

export const PublicProfileDataDispatcher = ({ children }) => {

  const personDispatch = usePersonDispatch();

  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case PUBLIC_PROFILE_DISPATCH_ACTIONS.ADD:
        createPublicProfile(personDispatch, action.payload);
        return state;
      case PUBLIC_PROFILE_DISPATCH_ACTIONS.UPDATE:
        updatePublicProfile(personDispatch, action.payload);
        return state;
      case PUBLIC_PROFILE_DISPATCH_ACTIONS.SETSTATUS:
        setPublicProfileStatus(personDispatch, action.payload);
        return state;
      case PUBLIC_PROFILE_DISPATCH_ACTIONS.FETCH:
        fetchPublicProfile(publicProfileDispatch, action.payload);
        return state;
      case PUBLIC_PROFILE_DISPATCH_ACTIONS.FETCHED:
        return action.payload.profile;
      default:
        return state;
    }
  }, [personDispatch]);

  const initialState = {};

  const [publicProfile, publicProfileDispatch] = useReducer(reducer, initialState);

  return (
    <PublicProfileDispatch.Provider
      value={{
        publicProfile,
        publicProfileDispatch,
      }}
    >
      {children}
    </PublicProfileDispatch.Provider>
  );
};

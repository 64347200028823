import { readEntity } from "../../data/delicDataClient";
import { withTryCatch } from "../../utils/dispatchersHelpers";

export async function fetchReferences(dispatch, { personId, projectId, callback }) {
    withTryCatch(async () => {
        const res = await readEntity("AllReferencesInProject", { personId, projectId });
        if (res) {
            dispatch({ type: "fetched", payload: res.references });
            if (callback) {
                callback();
            }
        } else {
            if (callback) {
                callback();
            }
            throw new Error("Couldn't read references in the project");
        }
    })
}

import { createBrowserHistory } from 'history'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createRootReducer from './rootReducer'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: [...getDefaultMiddleware({ immutableCheck: false }), routerMiddleware(history)]
})

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default
        store.replaceReducer(newRootReducer)
    })
}

export default store
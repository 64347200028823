import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import {
  onStart,
  getProjectSuccess,
  selectProjectById,
  removeMemberFromProjectSuccess,
} from "./projectsSlice";
import { fetchTasksInProjectSuccess, selectTaskById } from "./tasksSlice";
import {
  getWorksInProjectSuccess,
  getLatestComponentsSuccess,
  fetchArchivedWorksInProject,
} from "./componentsSlice";
import { onConfirmTransferSuccess } from "./transferManagerSlice";
import { memberEntity } from "../schemas";
import get from "lodash/get";

export const membersAdapter = createEntityAdapter({ loading: false });

function upsertMembers(state, { payload }) {
  const { members } = payload;
  if (members) {
    membersAdapter.upsertMany(state, members);
  }
}

export const slice = createSlice({
  name: "members",
  initialState: membersAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onStart, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectSuccess, (state, action) => {
      membersAdapter.upsertMany(state, action.payload.project.members);
      membersAdapter.upsertMany(state, action.payload.project.owners);
      state.loading = false;
    });
    builder.addCase(removeMemberFromProjectSuccess, (state, action) => {
      membersAdapter.removeOne(state, action.payload.personId);
    });
    builder.addCase(fetchTasksInProjectSuccess, (state, { payload }) => {
      payload.tasks.forEach((t) => {
        membersAdapter.upsertMany(state, t.contributor);
        if (t.comments.length) {
          t.comments.forEach((c) => {
            c.author && membersAdapter.upsertOne(state, c.author);
          });
        }
      });
      state.loading = false;
    });
    builder.addCase(getWorksInProjectSuccess, upsertMembers);
    builder.addCase(getLatestComponentsSuccess, upsertMembers);
    builder.addCase(fetchArchivedWorksInProject, upsertMembers);
    builder.addCase(onConfirmTransferSuccess, (state, { payload }) => {
      if (payload.projectInvitation) {
        const {
          entities: { members },
        } = normalize(payload.projectInvitation.invitee[0], memberEntity);
        membersAdapter.upsertMany(state, members);
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const {
  selectById: selectMemberById,
  selectIds: selectMemberIds,
  selectEntities: selectMemberEntities,
  selectAll: selectAllMembers,
  selectTotal: selectTotalMembers,
} = membersAdapter.getSelectors((state) => state.members);

export const selectMembersByProjectId = (projectId) =>
  createSelector(
    [
      (state) => selectProjectById(state, projectId),
      (state) => state.members.entities,
    ],
    (project, members) => {
      if (project && members) {
        return get(project, "members", [])
          .concat(get(project, "owners", []))
          .reduce((arr, id) => {
            if (members[id]) {
              return arr.concat(members[id]);
            }
            return arr;
          }, []);
      }
    }
  );

export const selectContributorsByTaskId = (taskId) =>
  createSelector(
    [
      (state) => selectTaskById(state, taskId),
      (state) => state.members.entities,
    ],
    (task, members) =>
      (task.contributor || []).reduce(
        (arr, cId) => (members[cId] ? [...arr, members[cId]] : arr),
        []
      )
  );

export const selectContributors = (ids) =>
  createSelector([(state) => state.members.entities], (members) => {
    return (
      ids &&
      ids.reduce((arr, id) => (members[id] ? arr.concat(members[id]) : arr), [])
    );
  });

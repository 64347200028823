import React, { Suspense, useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { Helmet } from 'react-helmet'
import { useAuth } from './context/AuthContext'
import { SimpleDelicLoader } from "delic-ui";
import DelicCookiePopup from "./components/Cookies/CookieConsentPopup";
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module'
import DispatcherProvider from "./dispatcher/DispatcherProvider";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";


const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))

function Home() {
  const user = useAuth()
  return user ?
    <>
      <DelicCookiePopup logged={true} />
      <AuthenticatedApp />
    </>
    :
    <>
      <DelicCookiePopup />
      <UnauthenticatedApp />
    </>
}
function App() {

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MANAGER,
    events: {
      conversion: 'conversion'
    }
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    let privacyCookie = Cookies.get('cookieconsent_status');
    if (privacyCookie === "allow") {
      TagManager.initialize(tagManagerArgs);
    }
  });

  return (
    <Elements stripe={stripePromise}>
      <Helmet>
        <title>Streamline Media</title>
        <meta name="description" content="Your Space for Sound" />
        {/* <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" /> */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg"/>
        <meta name="msapplication-TileColor" content="#2d2d31" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Suspense fallback={<SimpleDelicLoader />}>
        <AuthProvider>
            <DispatcherProvider.NavigationDispatcher>
              <Home />
            </DispatcherProvider.NavigationDispatcher>
        </AuthProvider>
      </Suspense>
    </Elements>
  );
}

export default App;

import { readEntity } from "../../data/delicDataClient";
import { withTryCatch } from "../../utils/dispatchersHelpers";

export async function fetchLives(dispatch, { personId, projectId }) {
    withTryCatch(async () => {
        const res = await readEntity("AllLiveStreamsInProject",{ personId, projectId });
        if (res) {
            dispatch({ type: "fetched", payload: res.liveStreams });
        } else {
            throw new Error("Couldn't read livestreams in the project");
        }
    })
}

import React, {useCallback, useReducer} from "react";

export const NavigationDispatch = React.createContext(null);

export const NavigationDispatcher = ({children}) => {

  const initialState = {
    isTaskPanelOpen: false,
    isPersonPanelOpen: false
  };

  const reducer = useCallback( (state, action) => {
    switch (action.type) {
      case "openTaskPanel":
        return {
          isTaskPanelOpen: true,
          isPersonPanelOpen: false
        };
      case "openPersonPanel":
        return {
          isTaskPanelOpen: false,
          isPersonPanelOpen: true
        };
      case "closePanels":
        return {
          isTaskPanelOpen: false,
          isPersonPanelOpen: false
        };
      default:
        return state;
    }
  }, []);

  const [navigation, navigationDispatch] = useReducer(reducer, initialState);

  return (
    <NavigationDispatch.Provider
      value={{
        navigation,
        navigationDispatch,
      }}
    >
      {children}
    </NavigationDispatch.Provider>
  );
};

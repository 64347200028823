import React from "react";
import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";

export default function DelicCookiePopup({ logged = false }) {

  const cookiePolicy = logged ? "/cookie-policy" : "/cookie-policy";

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MANAGER
  };

  function onAcceptTriggerTagManager() {
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton={true}
      buttonText="I Agree"
      declineButtonText="No thanks"
      cookieName="cookieconsent_status"
      cookieValue="allow"
      declineCookieValue="deny"
      cookieSecurity={true}
      sameSite="strict"
      disableStyles={true}
      containerClasses="cookie-consent-container"
      buttonWrapperClasses="cookie-consent-button-wrapper"
      buttonClasses="cookie-consent-approve-button"
      declineButtonClasses="cookie-consent-decline-button"
      contentClasses="cookie-consent-content"
      flipButtons={true}
      onAccept={onAcceptTriggerTagManager}
    >
      <span>We’d like your consent to use analytical cookies and similar technologies (&ldquo;Cookies&rdquo;) to inform improvements to Streamline Media for you and to analyse the effectiveness of our external marketing.</span><br/>
      <span className="cookie-consent-content-secondary">To find out more about the use of Cookies, please see our <a
        href={cookiePolicy} rel="noopener noreferrer">Cookie Policy</a>. Please click &ldquo;I Agree&rdquo; if you consent to our use of Cookies or click &ldquo;No thanks&rdquo; (this won’t affect your experience of the app)</span>
    </CookieConsent>
  );
}

import React, { useCallback, useReducer } from "react";
import { usePersonDispatch } from "../person/PersonDispatch";
import { createLivestream, deleteLivestream, publishLivestream, unpublishLivestream } from "./livestreamService";

export const LivestreamDispatch = React.createContext(null);

export const LivestreamDispatcher = ({ children }) => {

    const personDispatch = usePersonDispatch()

    const reducer = useCallback((state, action) => {
        switch (action.type) {
            case "add":
                createLivestream(personDispatch, action.payload);
                return state;
            case "delete":
                deleteLivestream(personDispatch, action.payload);
                return state;
            case "unpublish":
                unpublishLivestream(personDispatch, action.payload);
                return state;
            case "publish":
                publishLivestream(personDispatch, action.payload);
                return state;
            case "set":
                return action.payload;
            default:
                return state;
        }
    }, [personDispatch]);

    const [liveStream, liveStreamDispatch] = useReducer(reducer, null);

    return (
        <LivestreamDispatch.Provider
            value={{
                liveStream,
                liveStreamDispatch,
            }}
        >
            {children}
        </LivestreamDispatch.Provider>
    );
};

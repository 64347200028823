/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:233e5167-ec61-4f5e-ae9a-ad056d840b99",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_iUxJVelKu",
    "aws_user_pools_web_client_id": "v2oeu4rvjj5j96cq673ski2br",
    "oauth": {
        "domain": "streamlinedf48c1ad-df48c1ad-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.streamline-media.net/",
        "redirectSignOut": "https://app.streamline-media.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "delic-user-content-storage104221-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  readEntity,
  curriedCreateEntity,
  curriedDeleteEntity,
  curriedUpdateEntity,
  updateEntity,
} from "../../data/delicDataClient";
import get from "lodash/get";
import { COMPONENT_TYPES } from "../../utils/fileUploadHelpers";
import debounce from "lodash/debounce";
import { componentsSchema } from "../schemas";
import { normalize } from "normalizr";
import omit from "lodash/omit";
import { getLastNumber } from "../../components/Component/helpers";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import { deleteCommentSuccess, fetchCommentsSuccess } from "./commentsSlice";
import { upsertComments, createCommentSuccess } from "../actions";
import { onConfirmTransferSuccess } from "./transferManagerSlice";
import { notEmpty, objIsNotEmpty } from "../../utils/basic";

export const componentsAdapter = createEntityAdapter();

const initialState = componentsAdapter.getInitialState({
  latestComponents: null,
  isLoadingLatest: false,
  isLoadingInProject: false,
  isLoadingArchivedInProject: false,
  isLoadingUpdate: false,
  error: null,
  archivedError: null,
  latestError: null,
  importingState: "IDLE",
  importError: null,
  byProjectId: {},
});

function startLoadingLatest(state) {
  state.isLoadingLatest = true;
}

function loadingFailedLatest(state, action) {
  state.isLoadingLatest = false;
  state.error = action.payload;
}

function startLoadingInProject(state) {
  state.isLoadingInProject = true;
}

function loadingFailedInProject(state, action) {
  state.isLoadingInProject = false;
  state.error = action.payload;
}

function startLoadingUpdate(state) {
  state.isLoadingUpdate = true;
}

function loadingUpdateFailed(state, action) {
  state.isLoadingUpdate = false;
  state.error = action.payload;
}

function startLoadingArchivedInProject(state) {
  state.archivedError = null;
  state.isLoadingArchivedInProject = true;
}

function loadingArchivedFailedInProject(state, action) {
  state.isLoadingArchivedInProject = false;
  state.archivedError = action.payload;
}

function setError(state, action) {
  state.error = action.payload;
}

function upsertComponents(state, { payload }) {
  const { components, componentVersions } = payload;
  if (components) {
    componentsAdapter.upsertMany(state, components);
  }
  if (componentVersions) {
    componentsAdapter.upsertMany(state, componentVersions);
  }
}

function replaceComponentsInProject(state, { payload }) {
  upsertComponents(state, { payload });
  const { projectId, result, hasArchivedComponents } = payload;
  state.byProjectId[projectId] = { components: result.components };
  if (hasArchivedComponents != null) {
    state.byProjectId[projectId].hasArchivedComponents = hasArchivedComponents;
  }
  state.error = null;
  state.isLoadingInProject = false;
}

function upsertComponentsInProject(state, { payload }) {
  upsertComponents(state, { payload });
  const { projectId, result, hasArchivedComponents } = payload;

  if (state.byProjectId[projectId]?.components?.length > 0) {
    const { components } = state.byProjectId[projectId];
    // if components were optimistically added and the components fetch returns late, this may create duplicates
    const uniqComponents = [
      ...[...components, ...result.components]
        .reduce((map, c) => {
          map.has(get(c, "component[0]")) || map.set(get(c, "component[0]"), c);
          return map;
        }, new Map())
        .values(),
    ];
    state.byProjectId[projectId].components = uniqComponents;
  } else {
    const { components } = result;
    state.byProjectId[projectId] = { components };
  }

  if (hasArchivedComponents != null) {
    state.byProjectId[projectId].hasArchivedComponents = hasArchivedComponents;
  }
  state.error = null;
  state.isLoadingInProject = false;
}

const components = createSlice({
  name: "components",
  initialState,
  reducers: {
    getLatestComponentsStart: startLoadingLatest,
    getLatestComponentsSuccess(state, { payload }) {
      upsertComponents(state, { payload });
      const { latestComponents } = payload;
      state.latestComponents = latestComponents.map((c) => c.component[0].id);
      state.latestError = null;
      state.isLoadingLatest = false;
    },
    getLatestComponentsFailed: loadingFailedLatest,
    getWorksInProjectStart: startLoadingInProject,
    getWorksInProjectSuccess: upsertComponentsInProject,
    getWorksInProjecFailed: loadingFailedInProject,
    getArchivedWorksInProjectStart: startLoadingArchivedInProject,
    getArchivedWorksInProjectSuccess(state, { payload }) {
      const { projectId, result } = payload;
      upsertComponents(state, { payload });
      if (state.byProjectId[projectId]) {
        state.byProjectId[projectId].archivedComponents = result.components;
      } else {
        state.byProjectId[projectId] = {
          archivedComponents: result.components,
        };
      }
      state.archivedError = null;
      state.isLoadingArchivedInProject = false;
    },
    getArchivedWorksInProjecFailed: loadingArchivedFailedInProject,
    getUploadWorkInProjectStart: startLoadingInProject,
    getUploadWorkInProjectSuccess(state, { payload }) {
      const { component, projectId, groupedComponent } = payload;
      componentsAdapter.addOne(state, component);
      state.byProjectId[projectId].components = state.byProjectId[
        projectId
      ].components.concat(groupedComponent);
      state.error = null;
      state.isLoadingInProject = false;
    },
    getUploadWorkInProjectFailed: loadingFailedInProject,
    getUploadVersionInProjectStart: startLoadingInProject,
    getUploadVersionInProjectSuccess(state, { payload }) {
      upsertComponents(state, { payload });
      const { parentComponentId, component } = payload;
      state.entities[parentComponentId].versions = [component.id].concat(
        state.entities[parentComponentId].versions
      );
      state.error = null;
      state.isLoadingInProject = false;
    },
    getUploadVersionInProjectFailed: loadingFailedInProject,
    getUpdateWorkInProjectStart: startLoadingUpdate,
    getUpdateWorkInProjectSuccess(state, { payload }) {
      const { updatedComponent } = payload;
      componentsAdapter.upsertOne(state, updatedComponent);
      state.error = null;
      state.isLoadingInProject = false;
    },
    getUpdateWorkInProjectFailed: loadingUpdateFailed,
    getUpdateComponentFileInProjectStart: startLoadingUpdate,
    getUpdateComponentFileInProjectSuccess(state, { payload }) {
      const { updatedFile, componentId } = payload;
      state.entities[componentId].file[0] = updatedFile;

      state.error = null;
      state.isLoadingInProject = false;
    },
    getUpdateComponentFileInProjectFailed: loadingUpdateFailed,
    getUpdateGroupNameStart: startLoadingInProject,
    getUpdateGroupNameSuccess(state, { payload }) {
      const { componentIds, newGroupName, projectId } = payload;
      state.byProjectId[projectId].components.forEach((c) => {
        if (componentIds.some((id) => id === c.component[0])) {
          c.componentGroup = newGroupName;
        }
      });
      state.error = null;
      state.isLoadingInProject = false;
    },
    getUpdateGroupNameFailed: loadingFailedInProject,
    getUpdateGroupNameAndOrderStart: startLoadingInProject,
    getUpdateGroupNameAndOrderSuccess: replaceComponentsInProject,
    getUpdateGroupNameAndOrderFailed: loadingFailedInProject,
    getUpdateGroupOrderWeightSuccess: replaceComponentsInProject,
    getUpdateGroupOrderWeightFailed: loadingFailedInProject,
    toggleArchiveFailed: setError,
    createComponentContributorFailed: setError,
    deleteRecordingContributorRoleFailed: setError,
    addCommentToComponent(state, { payload }) {
      const { comment, projectId, componentId } = payload;
      state[projectId].components = state[projectId].components.map(
        (componentWrapper) => {
          if (componentWrapper.component[0].id === componentId) {
            componentWrapper.component[0].comments = componentWrapper.component[0].comments.concat(
              comment
            );
          } else {
            const index = componentWrapper.component[0].versions.findIndex(
              (v) => v.id === componentId
            );
            if (index >= 0) {
              componentWrapper.component[0].versions[
                index
              ].comments = componentWrapper.component[0].versions[
                index
              ].comments.concat(comment);
            }
          }
          return componentWrapper;
        }
      );
    },
    onImportFilesStart(state) {
      state.importingState = "LOADING";
    },
    resetImportState(state, { payload }) {
      const { importingState } = payload;
      state.importingState = importingState;
      state.importError = null;
    },
    onImportFilesFail(state, action) {
      state.importingState = "DONE";
      state.importError = action.payload;
    },
    toggleSharedComponent(state, { payload }) {
      const { componentId } = payload;
      const hasShare = state.entities[componentId].numberOfSharedComponents > 0;
      state.entities[componentId].numberOfSharedComponents = hasShare ? 0 : 1;
    },
    toggleArchivedSuccess(state, { payload }) {
      const { component, isArchived, projectId } = payload;

      const compId = component.component[0];
      if (isArchived) {
        state.byProjectId[projectId] = {
          ...state.byProjectId[projectId],
          components: state.byProjectId[projectId].components.filter(
            (c) => c.component[0] !== compId
          ),
          archivedComponents: state.byProjectId[projectId].archivedComponents
            ? [...state.byProjectId[projectId].archivedComponents, component]
            : [component],
        };
      } else {
        state.byProjectId[projectId].components = [
          ...state.byProjectId[projectId].components,
          component,
        ];
        state.byProjectId[projectId].archivedComponents = state.byProjectId[
          projectId
        ].archivedComponents.filter((c) => c.component[0] !== compId);
      }
      state.entities[compId].isArchived = !state.entities[compId].isArchived;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCommentSuccess, (state, { payload }) => {
      const { comment, commentableId } = payload;

      if (state.entities[commentableId]) {
        state.entities[commentableId].comments = [comment.id].concat(
          state.entities[commentableId].comments
        );
        state.entities[commentableId].numberOfComments++;
      }
    });
    builder.addCase(deleteCommentSuccess, (state, { payload }) => {
      const { commentId, commentableId } = payload;
      if (state.entities[commentableId]) {
        state.entities[commentableId].comments = state.entities[
          commentableId
        ].comments.filter((id) => id !== commentId);
        state.entities[commentableId].numberOfComments--;
      }
    });
    builder.addCase(fetchCommentsSuccess, (state, { payload }) => {
      const { ids, commentableId } = payload;

      if (state.entities[commentableId]) {
        state.entities[commentableId].comments = ids;
      }
    });
    builder.addCase(onConfirmTransferSuccess, (state, { payload }) => {
      const {
        components = [],
        result,
        selectedComponentIdToShare,
        sharedComponent,
        projectId,
      } = payload;
      const componentsToAdd = {};
      const componentVersions = {};
      const versionsOf = {};
      Object.keys(components).forEach((key) => {
        const component = components[key];

        const isVersionOfId = get(component, "isVersionOf[0].id", "");
        const isVersionOfProjectId = get(
          component,
          "isVersionOf[0].project[0].id",
          ""
        );

        // Add the shared component if there is
        if (
          objIsNotEmpty(sharedComponent) &&
          component.id === selectedComponentIdToShare
        ) {
          component.sharedComponents = notEmpty(component.sharedComponents)
            ? component.sharedComponents.concat([sharedComponent])
            : [sharedComponent];
        }

        if (
          isVersionOfId &&
          (isVersionOfProjectId === projectId ||
            (!isVersionOfProjectId && projectId === "Dashboard"))
        ) {
          componentVersions[component.id] = component;
          versionsOf[isVersionOfId] = versionsOf[isVersionOfId]
            ? versionsOf[isVersionOfId].concat([component.id])
            : [component.id];
        } else {
          componentsToAdd[component.id] = component;
        }
      });
      if (Object.keys(versionsOf).length > 0) {
        Object.keys(versionsOf).forEach((id) => {
          state.entities[id].versions = state.entities[id].versions
            ? state.entities[id].versions.concat(versionsOf[id])
            : [versionsOf[id]];
        });

        upsertComponents(state, { payload: { componentVersions } });
      }
      const latestIds = Object.keys(componentsToAdd);
      if (latestIds.length > 0) {
        const payload = { projectId, result, components: componentsToAdd };
        if (projectId === "Dashboard") {
          upsertComponents(state, { payload });
        } else {
          upsertComponentsInProject(state, { payload });
        }
        state.latestComponents = state.latestComponents
          ? latestIds.concat(state.latestComponents)
          : latestIds;
      }
    });
  },
});

export const selectComponentsByProjectId = (projectId) =>
  createSelector(
    [
      (state) => state.components.entities,
      (state) => state.components.byProjectId[projectId],
    ],
    (components, byProject) => {
      if (byProject && byProject.components) {
        return byProject.components.map((c) => {
          return { ...c, component: [components[c.component[0]]] };
        });
      }
    }
  );

export const selectArchivedComponentsByProjectId = (projectId) =>
  createSelector(
    [
      (state) => state.components.entities,
      (state) => state.components.byProjectId[projectId],
    ],
    (components, byProject) => {
      if (byProject && byProject.archivedComponents) {
        return byProject.archivedComponents.map((c) => ({
          ...c,
          component: [components[c.component[0]]],
        }));
      }
    }
  );

export const selectLatestComponents = (index) =>
  createSelector(
    [
      (state) => state.components.entities,
      (state) => state.components.latestComponents,
    ],
    (components, ids) => {
      if (ids) {
        return index
          ? ids.slice(0, index).map((id) => components[id])
          : ids.map((id) => components[id]);
      }
    }
  );

export const selectVersionsByParentId = (parentId) =>
  createSelector(
    [
      (state) => state.components.entities,
      (state) => state.components.entities[parentId],
    ],
    (components, parentComponent) => {
      return reverse(
        sortBy(
          [parentComponent.id, ...get(parentComponent, "versions", [])].map(
            (id) => {
              const component = components[id];
              const version = getLastNumber(component.version);
              return { ...component, version };
            }
          ),
          (v) => v.version
        )
      );
    }
  );

export const selectComponentGroupData = (componentId) =>
  createSelector(
    [
      (state) =>
        state.components.byProjectId[componentId] || {
          componentGroup: "General",
          componentGroupOrderWeight: 0,
          componentOrderWeight: 0,
        },
    ],
    ({ componentGroup, componentGroupOrderWeight, componentOrderWeight }) => {
      return {
        componentGroup,
        componentGroupOrderWeight,
        componentOrderWeight,
      };
    }
  );

export const selectDenormalisedComponents = () =>
  createSelector(
    [
      (state) => state.components.entities,
      (state) => state.projects.entities,
      (state) => state.components.latestComponents,
    ],
    (components, projects, latestComponents) => {
      if (
        Object.keys(components).length &&
        Object.keys(projects).length &&
        latestComponents &&
        latestComponents.length
      ) {
        const denormalizedComponents = Object.values(components).map((c) => {
          const versions = c.versions
            .map((id) => components[id])
            .filter((v) => v != undefined);
          return {
            ...c,
            project: [projects[get(c, "project[0]")]] || [],
            versions,
          };
        });
        return denormalizedComponents;
      }
    }
  );

export const {
  selectById: selectComponentById,
  selectIds: selectComponentIds,
  selectEntities: selectComponentEntities,
  selectAll: selectAllComponents,
  selectTotal: selectTotalComponents,
} = componentsAdapter.getSelectors((state) => state.components);

export const {
  getLatestComponentsStart,
  getLatestComponentsSuccess,
  getLatestComponentsFailed,
  getWorksInProjectStart,
  getWorksInProjectSuccess,
  getWorksInProjecFailed,
  getArchivedWorksInProjectStart,
  getArchivedWorksInProjectSuccess,
  getArchivedWorksInProjecFailed,
  getUploadWorkInProjectStart,
  getUploadWorkInProjectSuccess,
  getUploadWorkInProjectFailed,
  getUploadVersionInProjectStart,
  getUploadVersionInProjectSuccess,
  getUploadVersionInProjectFailed,
  getUpdateWorkInProjectStart,
  getUpdateWorkInProjectSuccess,
  getUpdateWorkInProjectFailed,
  getUpdateComponentFileInProjectStart,
  getUpdateComponentFileInProjectSuccess,
  getUpdateComponentFileInProjectFailed,
  getUpdateGroupNameStart,
  getUpdateGroupNameSuccess,
  getUpdateGroupNameFailed,
  getUpdateGroupNameAndOrderStart,
  getUpdateGroupNameAndOrderSuccess,
  getUpdateGroupNameAndOrderFailed,
  getUpdateGroupOrderWeightSuccess,
  getUpdateGroupOrderWeightFailed,
  toggleArchivedSuccess,
  toggleArchiveFailed,
  createComponentContributorFailed,
  deleteRecordingContributorRoleFailed,
  deleteWorkRecordingContributorRoleFailed,
  addCommentToComponent,
  onImportFilesStart,
  onImportFilesFail,
  resetImportState,
  toggleSharedComponent,
} = components.actions;

export default components.reducer;

export const normalizeComponents = (componentsPayload) => {
  const {
    entities: { components, componentVersions, members, project, comments },
    result,
  } = normalize({ components: componentsPayload }, componentsSchema);
  return { components, componentVersions, members, result, project, comments };
};

// vvvvvvvvvvv         THUNKS         vvvvvvvvvvv //

// ------------------- FETCH ------------------ //

export const fetchLatestComponents = ({ personId }) => async (dispatch) => {
  try {
    dispatch(getLatestComponentsStart());
    const works = await readEntity("AllUserFiles", {
      personId,
      role: "uploader",
      isArchived: false,
      skip: 0,
      limit: 2000,
      sharedComponentStatus: "PUBLIC",
    });
    const processedComponents = businessLogicFilter(works).map((c) => ({
      ...c.groupInProject,
      component: [omit(c, "groupInProject")],
    }));
    const {
      components,
      componentVersions,
      members,
      project,
    } = normalizeComponents(processedComponents);
    dispatch(
      getLatestComponentsSuccess({
        components,
        latestComponents: processedComponents,
        componentVersions,
        members,
        project,
      })
    );
  } catch (err) {
    dispatch(getLatestComponentsFailed(err.toString()));
  }
};

export const fetchWorksInProject = ({ personId, projectId }) => async (
  dispatch
) => {
  try {
    dispatch(getWorksInProjectStart());
    const res = await readEntity("AllComponentsOfProject", {
      personId,
      projectId,
      sharedComponentStatus: "PUBLIC",
    });
    if (res.components) {
      const {
        components,
        componentVersions,
        members,
        comments,
        result,
      } = normalizeComponents(res.components);
      dispatch(upsertComments(comments));
      dispatch(
        getWorksInProjectSuccess({
          result,
          hasArchivedComponents: res.hasArchivedComponents,
          projectId,
          components,
          componentVersions,
          members,
          comments,
        })
      );
    } else {
      dispatch(
        getWorksInProjecFailed(
          "We ecountered an error while retrieving your files. Please refresh the page. If problem persists, please contact support."
        )
      );
    }
  } catch (err) {
    console.warn(`Project id: ${projectId}, Error: ${err}`);
    dispatch(
      getWorksInProjecFailed(
        "We ecountered an error while retrieving your files. Please refresh the page. If problem persists, please contact support."
      )
    );
  }
};

export const fetchArchivedWorksInProject = ({ personId, projectId }) => async (
  dispatch
) => {
  try {
    dispatch(getArchivedWorksInProjectStart());
    const res = await readEntity("ArchivedComponentsOfProject", {
      personId,
      projectId,
    });
    const { components, members, result } = normalizeComponents(
      res.archivedComponents
    );

    dispatch(
      getArchivedWorksInProjectSuccess({
        components,
        projectId,
        members,
        result,
      })
    );
  } catch (err) {
    dispatch(
      getArchivedWorksInProjecFailed(
        `Project id: ${projectId}, Error: ${err.toString()}`
      )
    );
  }
};

// ------------------- UPDATE ------------------ //
export const updateWorkInProjectDebounced = (...args) => (dispatch) =>
  updateWorkInProject(dispatch, ...args);
export const updateWorkInProject = debounce(
  async (dispatch, { payload, onComponentUpdate }) => {
    try {
      dispatch(getUpdateWorkInProjectStart());
      const { input, componentType } = payload;

      let updatedComponent;
      if (componentType === COMPONENT_TYPES.SourceFile) {
        const mutationPayload = { ...input, sourceFileInput: input.input };
        delete mutationPayload.input;
        updatedComponent = await updateSourceFileComponent({
          input: mutationPayload,
        });
      } else if (componentType === COMPONENT_TYPES.Recording) {
        const mutationPayload = { ...input, recordingInput: input.input };
        delete mutationPayload.input;
        updatedComponent = await updateRecordingComponent({
          input: mutationPayload,
        });
      } else {
        const mutationPayload = { ...input, componentInput: input.input };
        delete mutationPayload.input;
        updatedComponent = await updateComponent({
          input: mutationPayload,
          label: componentType,
        });
      }
      if (updatedComponent) {
        const { components } = normalizeComponents([
          { component: [updatedComponent] },
        ]);
        if (components) {
          const component = Object.values(components)[0];
          dispatch(
            getUpdateWorkInProjectSuccess({ updatedComponent: component })
          );
          onComponentUpdate();
        }
      }
    } catch (err) {
      dispatch(
        getUpdateWorkInProjectFailed(`Updating Error: ${err.toString()}`)
      );
    }
  },
  1000
);

export const updateComponentFileInProjectDebounced = (...args) => (dispatch) =>
  updateComponentFileInProject(dispatch, ...args);
export const updateComponentFileInProject = debounce(
  async (dispatch, { payload, onComponentUpdate }) => {
    try {
      dispatch(getUpdateComponentFileInProjectStart());
      let updatedFile = await updateComponentFileCurried(payload);
      dispatch(
        getUpdateComponentFileInProjectSuccess({
          updatedFile,
          componentId: payload.componentId,
        })
      );
      onComponentUpdate();
    } catch (err) {
      dispatch(
        getUpdateComponentFileInProjectFailed(
          `Updating Component File Error: ${err.toString()}`
        )
      );
    }
  },
  1000
);

export const updateGroupName = ({ payload }) => async (dispatch) => {
  try {
    dispatch(getUpdateGroupNameStart());
    const res = await updateEntity("ComponentGroupName", payload);
    if (res) {
      dispatch(
        getUpdateGroupNameSuccess({
          newGroupName: payload.componentGroup,
          componentIds: payload.componentIds,
          projectId: payload.projectId,
        })
      );
    }
  } catch (err) {
    dispatch(
      getUpdateGroupNameFailed(
        `Updating Component File Error: ${err.toString()}`
      )
    );
  }
};

export const updateGroupNameAndOrder = ({
  payloadUpdate,
  projectId,
  orderedComponents,
}) => async (dispatch) => {
  try {
    const { components, result } = normalizeComponents(orderedComponents);

    dispatch(
      getUpdateGroupNameAndOrderSuccess({ result, projectId, components })
    );
    const promises = [];
    payloadUpdate.forEach((input) =>
      promises.push(updateEntity("ComponentGroupNameAndOrder", input))
    );
    const res = await Promise.all(promises);
    if (res.length !== promises.length) {
      dispatch(
        getUpdateGroupNameAndOrderFailed("Updating Component Group name Error")
      );
    }
  } catch (err) {
    dispatch(
      getUpdateGroupNameAndOrderFailed(
        `Updating Component Group name Error: ${err.toString()}`
      )
    );
  }
};

export const updateGroupOrderWeight = ({
  payloadUpdate,
  projectId,
  newComponentsWithLabels,
}) => async (dispatch) => {
  try {
    const { components, result } = normalizeComponents(newComponentsWithLabels);
    dispatch(
      getUpdateGroupOrderWeightSuccess({ result, projectId, components })
    );
    const promises = [];
    payloadUpdate.forEach((input) =>
      promises.push(updateEntity("ComponentGroupOrderWeight", input))
    );
    const res = await Promise.all(promises);
    if (res.length !== promises.length) {
      dispatch(
        getUpdateGroupOrderWeightFailed("Updating Component group order Error")
      );
    }
  } catch (err) {
    dispatch(
      getUpdateGroupOrderWeightFailed(
        `Updating Component Group order Error: ${err.toString()}`
      )
    );
  }
};

// ------------------- ARCHIVE ------------------ //
export const toggleArchive = ({ input, projectId }) => async (
  dispatch,
  getState
) => {
  try {
    const id = await toggleArchiveComponent(input);

    if (id) {
      const {
        components: { byProjectId },
      } = getState();
      const component = [
        ...byProjectId[projectId].components,
        ...(byProjectId[projectId].archivedComponents || []),
      ].find((c) => c.component[0] === id);
      dispatch(
        toggleArchivedSuccess({
          component,
          isArchived: input.isArchived,
          projectId,
        })
      );
    }
  } catch (err) {
    dispatch(
      toggleArchiveFailed(`Updating Component File Error: ${err.toString()}`)
    );
  }
};

// ------------------- CONTRIBUTORS ------------------ //
export const createComponentContributor = ({ payload, isArchived }) => async (
  dispatch
) => {
  try {
    let res = await createComponentContribution(payload);
    if (res) {
      const { personId, projectId } = payload;
      if (isArchived) {
        dispatch(fetchArchivedWorksInProject({ personId, projectId }));
      } else {
        dispatch(fetchWorksInProject({ personId, projectId }));
      }
    }
  } catch (err) {
    dispatch(
      createComponentContributorFailed(
        `createComponentContributor Error: ${err.toString()}`
      )
    );
  }
};

export const createWorkContributor = ({
  payload,
  componentType,
  isArchived,
}) => async (dispatch) => {
  try {
    if (componentType === COMPONENT_TYPES.Recording) {
      const res = await createWorkContribution(payload);

      if (res) {
        const { personId, projectId } = payload;
        if (isArchived) {
          dispatch(fetchArchivedWorksInProject({ personId, projectId }));
        } else {
          dispatch(fetchWorksInProject({ personId, projectId }));
        }
      }
    }
  } catch (err) {
    dispatch(
      createComponentContributorFailed(
        `createComponentContributor Error: ${err.toString()}`
      )
    );
  }
};

export const updateContributorRole = ({ payload, isArchived }) => async (
  dispatch
) => {
  try {
    let res = await updateComponentContributionRole(payload);
    if (res) {
      const { personId, projectId } = payload;
      if (isArchived) {
        dispatch(fetchArchivedWorksInProject({ personId, projectId }));
      } else {
        dispatch(fetchWorksInProject({ personId, projectId }));
      }
    }
  } catch (err) {
    dispatch(
      createComponentContributorFailed(
        `updateContributorRole Error: ${err.toString()}`
      )
    );
  }
};

export const updateWorkContributorRole = ({
  payload,
  componentType,
  isArchived,
  callback,
}) => async (dispatch) => {
  try {
    if (
      componentType === COMPONENT_TYPES.Recording ||
      componentType === COMPONENT_TYPES.Video
    ) {
      const res = await updateEntity("WorkContributionRole", payload);
      if (res && callback) {
        callback();
        const { personId, projectId } = payload;
        if (isArchived) {
          dispatch(fetchArchivedWorksInProject({ personId, projectId }));
        } else {
          dispatch(fetchWorksInProject({ personId, projectId }));
        }
      }
    }
  } catch (err) {
    dispatch(
      createComponentContributorFailed(
        `createComponentContributor Error: ${err.toString()}`
      )
    );
  }
};

export const deleteContributorRole = ({ payload, isArchived }) => async (
  dispatch
) => {
  try {
    let res = await deleteComponentContribution(payload);
    if (res) {
      const { personId, projectId } = payload;
      if (isArchived) {
        dispatch(fetchArchivedWorksInProject({ personId, projectId }));
      } else {
        dispatch(fetchWorksInProject({ personId, projectId }));
      }
    }
  } catch (err) {
    dispatch(
      deleteRecordingContributorRoleFailed(
        `deleteContributorRole Error: ${err.toString()}`
      )
    );
  }
};

export const deleteWorkContributorRole = ({
  payload,
  componentType,
  isArchived,
}) => async (dispatch) => {
  try {
    if (
      componentType === COMPONENT_TYPES.Recording ||
      componentType === COMPONENT_TYPES.Video
    ) {
      const res = await deleteWorkContribution(payload);
      if (res) {
        const { personId, projectId } = payload;
        if (isArchived) {
          dispatch(fetchArchivedWorksInProject({ personId, projectId }));
        } else {
          dispatch(fetchWorksInProject({ personId, projectId }));
        }
      }
    }
  } catch (err) {
    dispatch(
      deleteWorkRecordingContributorRoleFailed(
        `deleteWorkRecordingContributorRole Error: ${err.toString()}`
      )
    );
  }
};

// ------------ helper functions

function businessLogicFilter(components = []) {
  const byTime = (a, b) => b.date.getTime() - a.date.getTime();
  const byVersion = (a, b) => b.version - a.version;

  const componentsDictionary = {};
  components.forEach((comp) => {
    componentsDictionary[comp.id] = { ...componentsDictionary[comp.id], comp };
    if (notEmpty(comp.versions)) {
      comp.versions.forEach((ver) => {
        componentsDictionary[ver.id] = {
          ...componentsDictionary[ver.id],
          isVersionOf: comp,
        };
      });
    }
  });

  const workedWrappedComponents = [];

  Object.values(componentsDictionary).forEach((wrappedComp) => {
    // If the component is a version of another component in the same project don't go further
    const projectIdOfIsVersionOf = get(
      wrappedComp,
      "isVersionOf.project[0].id",
      ""
    );
    const projectId = get(wrappedComp, "comp.project[0].id", "");
    if (
      objIsNotEmpty(wrappedComp.isVersionOf) &&
      projectIdOfIsVersionOf === projectId
    )
      return;

    // For each component remove all the versions that don't belong to the same project
    const decoupledComponent = { ...wrappedComp.comp };
    const versionsNumbers = [
      {
        version: decoupledComponent.version,
        date: new Date(get(decoupledComponent, "lastModifiedDate.formatted")),
      },
    ];

    if (notEmpty(decoupledComponent.versions)) {
      const versions = [];
      decoupledComponent.versions.forEach((version) => {
        // Add to the versions list if has same project Id
        const componentWrapFound = componentsDictionary[version.id];
        const projectIdOfVersion = get(
          componentWrapFound,
          "comp.project[0].id",
          ""
        );
        if (projectId === projectIdOfVersion) {
          // Store the date of last modification for ordering
          versionsNumbers.push({
            version: version.version,
            date: new Date(get(version, "lastModifiedDate.formatted")),
          });
          versions.push(version);
        }
      });

      decoupledComponent.versions = versions;
    }

    const latest = versionsNumbers.sort(byVersion)[0];
    workedWrappedComponents.push({ ...latest, c: decoupledComponent });
  });

  return workedWrappedComponents.sort(byTime).map((wrapper) => wrapper.c);
}

async function updateComponent(payload) {
  return await curriedUpdateEntity("Component")(payload);
}

async function updateSourceFileComponent(payload) {
  return await curriedUpdateEntity("SourceFileComponent")(payload);
}

async function updateComponentFileCurried(payload) {
  return await curriedUpdateEntity("ComponentFile")(payload);
}

async function updateRecordingComponent(payload) {
  return await curriedUpdateEntity("RecordingComponent")(payload);
}

async function toggleArchiveComponent(payload) {
  return await curriedDeleteEntity("Component")(payload);
}

async function createComponentContribution(payload) {
  return await curriedCreateEntity("ComponentContribution")(payload);
}

async function createWorkContribution(payload) {
  return await curriedCreateEntity("WorkContribution")(payload);
}

async function updateComponentContributionRole(payload) {
  return await curriedUpdateEntity("ComponentContributionRole")(payload);
}

async function deleteComponentContribution(payload) {
  return await curriedDeleteEntity("ComponentContribution")(payload);
}

async function deleteWorkContribution(payload) {
  return await curriedDeleteEntity("WorkContribution")(payload);
}

import get from "lodash/get"
import { notEmpty, objIsNotEmpty } from "../../utils/basic";

export function sortingComponentLabels(firstComp, secondComp) {
  if (firstComp.componentOrderWeight < secondComp.componentOrderWeight) {
    return -1;
  }
  if (firstComp.componentOrderWeight > secondComp.componentOrderWeight) {
    return 1;
  }
  if (firstComp.componentOrderWeight === secondComp.componentOrderWeight) {
    if (firstComp.lastModifiedDate < secondComp.lastModifiedDate) {
      return -1;
    }
    if (firstComp.lastModifiedDate > secondComp.lastModifiedDate) {
      return 1;
    }
    return 0;
  }
}

export function sortingGroupLabels(firstComp, secondComp) {
  const left = get(firstComp, 'components[0]', {})
  const right = get(secondComp, 'components[0]', {})
  if (left.componentGroupOrderWeight < right.componentGroupOrderWeight) {
    return -1;
  }
  if (left.componentGroupOrderWeight > right.componentGroupOrderWeight) {
    return 1;
  }
  if (left.componentGroupOrderWeight === right.componentGroupOrderWeight) {
    if (left.lastModifiedDate < right.lastModifiedDate) {
      return -1;
    }
    if (left.lastModifiedDate > right.lastModifiedDate) {
      return 1;
    }
  }
  return 0;
}



// allComponentsInDestinationGroup must be already sorted by the group weight
export function orderComponents(personId, projectId, orderedComponents, draggableId, destination) {

  const targetComponent = orderedComponents.reduce((acc, group) => {
    const comp = group.components.find(c => c.id === draggableId)
    if (comp) acc.push(comp)
    return acc
  }, []).pop()

  const allComponentsInDestinationGroup = orderedComponents
    .find(componentsGroup => componentsGroup.group === destination.droppableId)
    .components.filter(c => c.id !== targetComponent.id)

  const componentsBefore = allComponentsInDestinationGroup.slice(0, destination.index)
  const componentsAfter = allComponentsInDestinationGroup.slice(destination.index, allComponentsInDestinationGroup.length)

  const toBeUpdated = [targetComponent].concat(componentsAfter)

  let currentIndex = componentsBefore.length ? componentsBefore[componentsBefore.length - 1].componentOrderWeight : -1

  const componentGroupOrderWeight = get(componentsBefore, '[0].componentGroupOrderWeight', get(componentsAfter, '[0].componentGroupOrderWeight', 0))

  const payloads = toBeUpdated.reduce((payloads, currComp) => {
    if (currComp.componentOrderWeight !== currentIndex || destination.draggableId !== targetComponent.componentGroup) {

      currentIndex++

      payloads.push({
        personId,
        projectId,
        componentIds: currComp.versions ? [currComp.id].concat(currComp.versions.map(c => c.id)) : [currComp.id],
        componentGroup: destination.droppableId,
        componentOrderWeight: currentIndex,
        componentGroupOrderWeight
      })
      // overwrite component for optimistic update
      currComp.componentOrderWeight = currentIndex
      currComp.componentGroup = destination.droppableId
    }
    return payloads
  }, [])

  // this is for the optimistic order
  const componentsInOrder = orderedComponents.reduce((arr, componentGroup) => {
    if (componentGroup.group === destination.droppableId) {
      return arr.concat(componentsBefore).concat(toBeUpdated)
    } else {
      const withoutTargetComponent = componentGroup.components.filter(c => c.id !== targetComponent.id)
      return arr.concat(withoutTargetComponent)
    }
  }, [])
  return {
    payloads, components: createGropedComponents(componentsInOrder)
  }
}

function createGropedComponents(components) {
  return components.map(g => {
    const groupedComponent = { componentGroup: g.componentGroup, componentGroupOrderWeight: g.componentGroupOrderWeight, componentOrderWeight: g.componentOrderWeight, component: [g] }
    delete g.componentOrderWeight
    delete g.componentGroupOrderWeight
    delete g.componentGroup
    return groupedComponent
  })
}

export function orderComponentsGroup(personId, projectId, orderedComponents, draggableId, destination) {

  const targetGroup = orderedComponents.filter(group => group.group === draggableId)
  const toBeReordered = orderedComponents.filter(g => g.group !== draggableId)

  const groupsBefore = toBeReordered.slice(0, destination.index)
  const groupsAfter = toBeReordered.slice(destination.index, toBeReordered.length)

  const orderedGroups = groupsBefore.concat(targetGroup).concat(groupsAfter)

  let payloadUpdate = []

  const newOrderedComponentByGroup = orderedGroups.reduce((arr, group, index) => {

    let updatedOrderOfGroup = []
    let componentIds = []
    let componentGroupOrderWeight;

    if (group.components[0].componentGroupOrderWeight !== index) {
      componentGroupOrderWeight = index
      updatedOrderOfGroup = group.components.map(c => {
        componentIds = componentIds.concat(c.id)
        return { ...c, componentGroupOrderWeight }
      })

      payloadUpdate = payloadUpdate.concat({
        personId,
        projectId,
        componentIds,
        componentGroupOrderWeight,
      })
    }

    if (updatedOrderOfGroup.length > 0) {
      return updatedOrderOfGroup.concat(arr)
    } else {
      return [...group.components.concat(arr)]
    }
  }, [])

  return { payloadUpdate, projectId, newComponentsWithLabels: createGropedComponents(newOrderedComponentByGroup) }

}

function getHighestComponentInGroup(componentsWithLabels, group) {
  const groupedComponents = componentsWithLabels.find(
    componentsWithLabel => componentsWithLabel.group === group
  );
  if (!objIsNotEmpty(groupedComponents)) return null;
  return groupedComponents.components[0];
}

export function getHighestComponentOrderAndGroupOrderForGroup(componentsWithLabels, componentGroup) {

  let componentGroupOrderWeight = 0;
  let componentOrderWeight = 0;

  if (!notEmpty(componentsWithLabels)) return { componentGroupOrderWeight, componentOrderWeight };

  const topComponent = getHighestComponentInGroup(componentsWithLabels, componentGroup);
  if (topComponent) {
    componentGroupOrderWeight = topComponent.componentGroupOrderWeight
      ? topComponent.componentGroupOrderWeight
      : 0;
    componentOrderWeight = topComponent.componentOrderWeight
      ? topComponent.componentOrderWeight + 1
      : 0;
  } else {
    componentGroupOrderWeight = get(componentsWithLabels[0], "components[0].componentGroupOrderWeight", 0);
    componentOrderWeight = 0;
  }

  return { componentGroupOrderWeight, componentOrderWeight };
}

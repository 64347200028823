import { handleError, notEmpty } from "./basic";
import { getLastNumber } from "../components/Component/helpers"

export const segregateByKey = (key, values) => values.reduce((acc, val) => {
  if (val[key]) {
    if (acc[val[key]]) {
      return { ...acc, [val[key]]: [...acc[val[key]], { ...val }] }
    }
    return { ...acc, [val[key]]: [{ ...val }] }
  } else {
    if (acc["NO_KEY"]) {
      return { ...acc, "NO_KEY": [...acc["NO_KEY"], { ...val }] }
    }
    return { ...acc, "NO_KEY": [{ ...val }] }
  }
}, {});

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export function sortByDate(a, b) {
  try {
    return a.date < b.date
      ? 1
      : a.date > b.date
        ? -1
        : 0;
  } catch (e) {
    handleError(e);
  }
  return 0;
}

export function sortByCreationDate(a, b) {
  try {
    return a.creationDate.formatted < b.creationDate.formatted
      ? 1
      : a.creationDate.formatted > b.creationDate.formatted
        ? -1
        : 0;
  } catch (e) {
    handleError(e);
  }
  return 0;
}

export function sortByLastModifiedDate(a, b) {
  try {
    return a.lastModifiedDate.formatted < b.lastModifiedDate.formatted
      ? 1
      : a.lastModifiedDate.formatted > b.lastModifiedDate.formatted
        ? -1
        : 0;
  } catch (e) {
    handleError(e);
  }
  return 0;
}

export function sortByVersionConvertedToString(a, b) {
  const left = getLastNumber(a.version)
  const right = getLastNumber(b.version)
  if (left === right) return 0
  return left < right ? 1 : -1
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array;
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

export function findAndReplace(content, stringToBeReplaced, replaceString) {
  if (!content) return content;
  if(isString(content)) {
    content.replace(stringToBeReplaced, replaceString);
  } else if ((isObject(content) || isArray(content)) && Object.keys(content).length > 0) {
    Object.keys(content).forEach(propertyKey => {
      if (isString(content[propertyKey])) {
        content[propertyKey] = content[propertyKey].replace(stringToBeReplaced, replaceString);
      } else {
        findAndReplace(content[propertyKey], stringToBeReplaced, replaceString);
      }
    })
  }
}

export function trimSpacesFromString(content) {
  return content.replace(/\s/g, "");
}

export function convertArrayToCreatableSelectableForm(array) {
  if (!notEmpty(array)) return [];
  return array
    .map(item => { return { value: item, label: item } });
}


export function redirectToComponent({ projectId, componentId, history }) {
  if (projectId.length && componentId.length) {
    history.push({
      pathname: `/project/${projectId}/work/${componentId}`,
    });
  } else if (projectId.length) {
    history.push({
      pathname: `/project/${projectId}/work`,
    });
  } else {
    history.push({ pathname: "/" });
  }
}

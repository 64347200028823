import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { handleError, isEmpty } from "../../utils/basic";
import {
  computeComponentVersion,
  getComponentInput,
  getFileInput,
} from "../../components/Component/helpers";
import get from "lodash/get";
import set from "lodash/set";
import { v4 as uuid } from "uuid";
import { removeFileToS3, uploadFileToS3 } from "../../utils/StorageManager";
import {
  getProgress,
  setProgressBar,
} from "../../components/Component/helpers";
import { addRelationship, createEntity } from "../../data/delicDataClient";
import { updateTask } from "./tasksSlice";
import { TaskStatus } from "../../components/Tasks/TaskStatus";
import {
  addInput,
  getPayloads,
  getSelectableVersionsOptions,
  getLatestVersionsOfComponents,
  getComponentsInSelectedProjectByGroupAndOrdered,
  getSameSpaceGroupedComponents,
  getLocation,
  createNewIdeaWithComment,
  getComponentGroupAndWeights,
  getFirstShareableComponent,
} from "./transferManagerSlice.helpers";
import {
  getReferenceType,
  linkCleaner,
} from "../../components/References/referenceParser";
import { normalizeComponents } from "./componentsSlice";
import { getlatestVersionsByProject } from "../../components/Component/helpers";
import { readEntity } from "../../data/delicDataClient";
import { LOCATION_CHANGE, push } from "connected-react-router";
import { getFileCategoryType, RECORDING, VIDEO } from "../../utils/fileUtils";

export const transferManagerAdapter = createEntityAdapter();

const initialState = transferManagerAdapter.getInitialState({
  isLoading: true,
  isTransfering: false,
  isOpen: false,
  canImport: true,
  error: false,
  targetParentComponent: null,
  targetLatestVersionComponent: null,
  isNewProject: false,
  newProjectName: "",
  selectedProjectId: "Dashboard",
  selectedTaskId: "",
  uploadedFiles: [],
  importedComponents: [],
  selectedComponentIdToShare: null,
  sharedComponentUri: "",
  projectInvitationPayload: null,
  isUploading: false,
  uploadProgress: 0,
  progressStatus: 0,
  currentUploadedFile: null,
  allProjectsComponents: [],
  componentsNotInCurrentProject: null,
  selectableVersionsOptions: [],
  isLoadingComponentForProject: null,
  idea: null,
  isLoadingAllComponentsUploadedByUser: false,
  errorGettingAllComponentsUploadedByUser: null,
});

const transferManager = createSlice({
  name: "transferManager",
  initialState,
  reducers: {
    onLoaded(state) {
      state.isLoading = false;
    },
    openPanel(state) {
      state.selectedProjectId = state.location.project
        ? state.location.project
        : "Dashboard";
      state.isOpen = true;
    },
    openPanelForNewVersion(state, { payload }) {
      const { parentComponent, selectedVersion, selectedProjectId } = payload;
      state.selectedProjectId = state.location.project
        ? state.location.project
        : "Dashboard";
      state.isOpen = true;
      state.targetParentComponent = parentComponent;
      state.targetLatestVersionComponent = selectedVersion;
      state.selectedProjectId = selectedProjectId;
    },
    setAllProjectsGroupedComponents(state, { payload }) {
      const { allProjectsGroupedComponents } = payload;

      state.allProjectsGroupedComponents = allProjectsGroupedComponents;
    },
    addUploadsFiles(state, { payload }) {
      const { uploadedFiles } = payload;

      state.uploadedFiles = [...state.uploadedFiles, ...uploadedFiles];
    },
    setUploadedComponentIsIdeaOrNot(state, { payload }) {
      const {
        selectedComponentIdToShare,
        uploadedFiles,
        importedComponents,
      } = state;
      const { position, isIdea } = payload;
      const targetFile = state.uploadedFiles[position];
      set(targetFile, "isIdea", isIdea);

      if (
        !selectedComponentIdToShare ||
        (isIdea &&
          get(targetFile, "payload.componentInput.id") ===
            selectedComponentIdToShare)
      ) {
        const newShareableComponent = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
        state.selectedComponentIdToShare = newShareableComponent;
      }
    },
    renameUploadedComponent(state, { payload }) {
      const { position, newTitle } = payload;
      const targetFile = state.uploadedFiles[position];
      set(
        targetFile,
        "previousTitle",
        get(targetFile, "payload.componentInput.title", "")
      );
      set(targetFile, "payload.componentInput.title", newTitle);
    },
    setStartedUploadingComponent(state, { payload }) {
      const { position } = payload;
      const targetFile = state.uploadedFiles[position];
      set(targetFile, "isUploading", true);
    },
    removeUploadedComponent(state, { payload }) {
      const { position } = payload;
      const uploadedFiles = state.uploadedFiles;
      state.uploadedFiles = [
        ...uploadedFiles.slice(0, position),
        ...uploadedFiles.slice(position + 1),
      ];
    },
    addImportedComponents(state, { payload }) {
      const { selectedProjectId, uploadedFiles, importedComponents } = state;
      const {
        personId,
        accountId,
        parentComponents,
        selectedComponentIdToShare,
      } = payload;

      const importedFiles = [];

      parentComponents.forEach((parentComponent) => {
        const isAlreadyAdded = state.importedComponents.find(
          (impFile) => impFile.payload.parentComponentId === parentComponent.id
        );
        if (isAlreadyAdded) return;

        const workId = get(parentComponent, "work[0].id", "");

        const fileInput = getFileInput(get(parentComponent, "file[0]"));
        const payload = {
          partyId: personId,
          componentInput: getComponentInput(
            parentComponent.title,
            computeComponentVersion(parentComponent.version).getNewNested,
            parentComponent.software
          ),
          fileInput,
          componentGroup: get(
            parentComponent,
            "groupInProject.componentGroup",
            "Default"
          ),
          componentGroupOrderWeight: 0,
          componentOrderWeight: 0,
          parentComponentId: parentComponent.id,
          latestVersionComponentId: parentComponent.id,
          workId,
          fullFileKey: "protected/" + accountId + "/" + fileInput.uri,
        };

        let uploaderId = "";
        const uploaderContr = get(parentComponent, "isContributionOf", []).find(
          (contr) => contr.role === "uploader"
        );
        if (uploaderContr) {
          uploaderId = get(uploaderContr, "contributor[0].accountId", "");
        }

        const fileCategoryType = getFileCategoryType(
          fileInput.mimeType,
          fileInput.fileType
        );

        const preparedFile = {
          payload,
          isVersion: true,
          sourceFile: parentComponent["__typename"] === "SourceFile",
          type: parentComponent["__typename"],
          idForComparison: uuid(),
          isUploaded: true,
          uploaderId: uploaderId,
          isIdea: false,
          parentComponent,
          label: fileCategoryType,
          isWorkRequired:
            fileCategoryType === RECORDING || fileCategoryType === VIDEO,
        };

        importedFiles.push(preparedFile);
      });

      importedFiles.forEach((item, position) => {
        const itemProjectId = get(item, "parentComponent.project[0].id", "");
        if (itemProjectId === selectedProjectId) {
          removeImportedComponent(state, { position });
        }
        if (item.isIdea) {
          setImportedComponentIsIdeaOrNot(state, { position, isIdea: false });
        }
      });

      if (!selectedComponentIdToShare) {
        const newShareableComponent = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
        if (newShareableComponent) {
          state.selectedComponentIdToShare = newShareableComponent;
        }
      }

      // default to ideas if in ideas panels
      if (state.location.ideas) {
        const ideas = importedFiles.map((file) => {
          file.isIdea = true;
          return file;
        });
        state.importedComponents = state.importedComponents.concat(ideas);
      } else {
        state.importedComponents = state.importedComponents.concat(
          importedFiles
        );
      }
    },
    setImportedComponentIsIdeaOrNot(state, { payload }) {
      const {
        selectedComponentIdToShare,
        uploadedFiles,
        importedComponents,
      } = state;
      const { position, isIdea } = payload;
      const targetFile = state.importedComponents[position];
      set(targetFile, "isIdea", isIdea);

      if (
        !selectedComponentIdToShare ||
        (isIdea &&
          get(targetFile, "payload.componentInput.id") ===
            selectedComponentIdToShare)
      ) {
        const newShareableComponent = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
        state.selectedComponentIdToShare = newShareableComponent;
      }
    },
    renameImportedComponent(state, { payload }) {
      const { position, newTitle } = payload;
      const targetFile = state.importedComponents[position];
      set(
        targetFile,
        "previousTitle",
        get(targetFile, "payload.componentInput.title", "")
      );
      set(targetFile, "payload.componentInput.title", newTitle);
    },
    removeImportedComponent(state, { payload }) {
      const { position } = payload;
      const importedComponents = state.importedComponents;
      state.importedComponents = [
        ...importedComponents.slice(0, position),
        ...importedComponents.slice(position + 1),
      ];
    },
    setComponentVersion(state, { payload }) {
      const { position, isUploadType, option } = payload;

      const { allProjectsGroupedComponents } = state;

      const file = isUploadType ? state.uploadedFiles[position] : [];
      if (option !== "" && allProjectsGroupedComponents) {
        let parentComponent = allProjectsGroupedComponents
          .filter(
            (comp) =>
              comp.versions && comp.versions.find((c) => c.id === option)
          )
          .pop();
        if (!parentComponent) {
          parentComponent = allProjectsGroupedComponents.find(
            (comp) => comp.id === option
          );
        }
        if (parentComponent) {
          const latestVersion =
            parentComponent.versions.find((c) => c.id === option) ||
            parentComponent;

          file.payload.parentComponentId = get(parentComponent, "id", "");
          file.payload.workId = get(parentComponent, "work[0].id", "");
          file.payload.latestVersionComponentId = option;
          file.payload.componentInput.version = computeComponentVersion(
            latestVersion.version
          ).increase;
          file.previousTitle = file.payload.componentInput.title;
          file.payload.componentInput.title = get(parentComponent, "title", "");
          file.isVersion = true;
        } else {
          throw new Error("No parent component when setting version");
        }
      } else {
        file.payload.parentComponentId = "";
        file.payload.latestVersionComponentId = "";
        file.isVersion = false;
        file.payload.componentInput.version = "1";
        file.payload.componentInput.title = file.previousTitle
          ? file.previousTitle
          : file.payload.componentInput.title;
        file.payload.componentGroup = file.previousGroup
          ? file.previousGroup
          : file.payload.componentGroup;
        file.payload.componentGroupOrderWeight = 0;
        file.payload.componentOrderWeight = 0;
      }
    },
    completeUploadForUploadedFile(state, { payload }) {
      const { accountId, position, componentId, fileKey } = payload;
      const targetFile = state.uploadedFiles[position];
      set(targetFile, "payload.componentInput.id", componentId);
      set(targetFile, "payload.fileInput.uri", fileKey);
      set(
        targetFile,
        "payload.fullFileKey",
        "protected/" + accountId + "/" + fileKey
      );
      set(targetFile, "isUploaded", true);
      set(targetFile, "isUploading", false);
    },
    setSelectedProject(state, { payload }) {
      const {
        selectedComponentIdToShare,
        uploadedFiles,
        importedComponents,
      } = state;
      const { newProjectId } = payload;

      state.selectedProjectId = newProjectId;
      state.isNewProject = false;
      state.newProjectName = "";

      if (!selectedComponentIdToShare && newProjectId !== "Dashboard") {
        const newShareableComponent = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
        if (newShareableComponent) {
          state.selectedComponentIdToShare = newShareableComponent;
        }
      }
    },
    removeIdeasFromDashboard(state) {
      const { importedComponents, uploadedFiles, selectedProjectId } = state;
      // Users can not have add work ideas in dashboard
      if (selectedProjectId === "Dashboard") {
        if (importedComponents.length) {
          state.importedComponents = importedComponents.map((c) => {
            c.isIdea = false;
            return c;
          });
        }
        if (uploadedFiles.length)
          state.uploadedFiles = uploadedFiles.map((c) => {
            c.isIdea = false;
            return c;
          });
      }
    },
    setSelectableVersionsOptions(state, { payload }) {
      state.selectableVersionsOptions = payload.selectableVersionsOptions;
    },
    resetVersionsIfFromDifferentProject(state) {
      const { selectedProjectId } = state;
      const uploadedComponents = state.uploadedFiles;
      for (let i = 0; i < uploadedComponents.length; i++) {
        const uploadedComp = uploadedComponents[i];
        // users can not upload versions of files in projects that don't have that file
        const versionOfId = get(
          uploadedComp,
          "payload.latestVersionComponentId",
          ""
        );
        if (versionOfId) {
          const versionOfComponent = state.selectableVersionsOptions.find(
            (opts) => opts.value === versionOfId
          );
          if (
            !versionOfComponent ||
            (versionOfComponent &&
              versionOfComponent.projectId !== selectedProjectId)
          ) {
            uploadedComp.payload.parentComponentId = "";
            uploadedComp.payload.latestVersionComponentId = "";
            uploadedComp.isVersion = false;
            uploadedComp.payload.componentInput.version = "1";
            uploadedComp.payload.componentInput.title = uploadedComp.previousTitle
              ? uploadedComp.previousTitle
              : uploadedComp.payload.componentInput.title;
            uploadedComp.payload.componentGroup = uploadedComp.previousGroup
              ? uploadedComp.previousGroup
              : uploadedComp.payload.componentGroup;
            uploadedComp.payload.componentGroupOrderWeight = 0;
            uploadedComp.payload.componentOrderWeight = 0;
          }
        }
      }
    },
    createProject(state, { payload }) {
      const { projectName } = payload;

      const {
        selectedComponentIdToShare,
        uploadedFiles,
        importedComponents,
      } = state;

      state.isNewProject = true;
      state.newProjectName = projectName;
      state.selectedProjectId = uuid();

      state.selectableVersionsOptions = [];

      if (!selectedComponentIdToShare) {
        const newShareableComponent = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
        if (newShareableComponent) {
          state.selectedComponentIdToShare = newShareableComponent;
        }
      }

      uploadedFiles.forEach((uploadedComp) => {
        // users can not upload versions of files in projects that don't have that file
        const versionOfId = get(
          uploadedComp,
          "payload.latestVersionComponentId",
          ""
        );
        if (versionOfId) {
          uploadedComp.payload.parentComponentId = "";
          uploadedComp.payload.latestVersionComponentId = "";
          uploadedComp.isVersion = false;
          uploadedComp.payload.componentInput.version = 1;
          uploadedComp.payload.componentInput.title = uploadedComp.previousTitle
            ? uploadedComp.previousTitle
            : uploadedComp.payload.componentInput.title;
          uploadedComp.payload.componentGroup = uploadedComp.previousGroup
            ? uploadedComp.previousGroup
            : uploadedComp.payload.componentGroup;
          uploadedComp.payload.componentGroupOrderWeight = 0;
          uploadedComp.payload.componentOrderWeight = 0;
        }
      });
    },
    setSelectedComponentToShare(state, { payload }) {
      let componentId;
      if (payload) {
        componentId = payload.componentId;
      } else {
        const { uploadedFiles, importedComponents } = state;
        componentId = getFirstShareableComponent(
          uploadedFiles,
          importedComponents
        );
      }

      state.selectedComponentIdToShare = componentId;
    },
    shareComponent(state, { payload }) {
      const { sharedComponentUri } = payload;
      state.sharedComponentUri = sharedComponentUri;
    },
    stopShareComponent(state) {
      state.selectedComponentIdToShare = null;
      state.sharedComponentUri = "";
    },
    inviteToProject(state, { payload }) {
      state.projectInvitationPayload = payload;
    },
    stopInviteToProject(state) {
      state.projectInvitationPayload = null;
    },
    selectTask(state, { payload }) {
      const { taskId } = payload;
      state.selectedTaskId = taskId;
    },
    hidePanel(state) {
      state.isOpen = false;
      if (isEmpty(state.uploadedFiles) && isEmpty(state.importedComponents)) {
        state.targetParentComponent = null;
        state.targetLatestVersionComponent = null;
      }
    },
    closePanel(state) {
      const location = state.location;
      return { ...initialState, location };
    },
    handleGeneralUploadProgression(state, { payload }) {
      const { numOfUploads, currentPosition, currentProgress } = payload;
      if (!state.isUploading) {
        state.isUploading = true;
      }
      const percentageForFile = 100 / numOfUploads;
      const previousUploadedFiles = percentageForFile * (currentPosition - 1);
      const globalProgress =
        previousUploadedFiles + percentageForFile * (currentProgress / 100);
      if (isFinite(globalProgress)) {
        state.uploadProgress = globalProgress;
      }
      if (globalProgress >= 100) {
        state.isUploading = false;
      }
    },
    setCurrentUploadedFile(state, { payload }) {
      const { uploadedFile } = payload;
      state.currentUploadedFile = uploadedFile;
    },
    setProgressStatus(state, { payload }) {
      state.setProgressStatus = payload;
    },
    onConfirmTransferStart(state) {
      state.isTransfering = true;
    },
    onConfirmTransferSuccess(state) {
      state.isTransfering = false;
    },
    onConfirmTransferFailure(state, { payload }) {
      state.isTransfering = false;
      state.error = payload.error;
    },
    setIdea(state, { payload }) {
      const { idea } = payload;
      const { selectedProjectId: projectId } = state;
      const type = getReferenceType(idea.url);
      state.idea = state.idea
        ? { ...state.idea, ...idea, type }
        : {
            url: linkCleaner(idea.url),
            projectId,
            comment: "Have a look at this!",
            type,
            ...idea,
          };
    },
    setIdeaType(state, { payload }) {
      const { type } = payload;
      state.idea.type = type;
    },
    setIdeaComment(state, { payload }) {
      const { comment } = payload;
      state.idea.comment = comment;
    },
    startLoadingComponentsForProject(state, { payload }) {
      state.isLoadingComponentForProject = payload.projectId;
    },
    finishLoadingComponentsForProject(state, { payload }) {
      state.isLoadingComponentForProject = null;
      if (payload) {
        state.error = payload.error;
      }
    },
    startLoadingAllComponentsUploadedByUser(state) {
      state.isLoadingAllComponentsUploadedByUser = true;
    },
    finishLoadingAllComponentsUploadedByUser(state, { payload }) {
      state.isLoadingAllComponentsUploadedByUser = false;
      if (payload) {
        const { error } = payload;
        state.errorGettingAllComponentsUploadedByUser = error;
      }
    },
    setComponentsNotInCurrentProject(state, { payload }) {
      state.componentsNotInCurrentProject =
        payload.componentsNotInCurrentProject;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOCATION_CHANGE, (state, { payload }) => {
      const {
        location: { pathname },
      } = payload;
      state.location = getLocation(pathname);
    });
  },
});

export const {
  onLoaded,
  openPanel,
  openPanelForNewVersion,
  setAllProjectsGroupedComponents,
  addUploadsFiles,
  setUploadedComponentIsIdeaOrNot,
  renameUploadedComponent,
  removeUploadedComponent,
  setStartedUploadingComponent,
  addImportedComponents,
  setImportedComponentIsIdeaOrNot,
  renameImportedComponent,
  removeImportedComponent,
  setComponentVersion,
  completeUploadForUploadedFile,
  setSelectedProject,
  createProject,
  setSelectedComponentToShare,
  shareComponent,
  stopShareComponent,
  inviteToProject,
  stopInviteToProject,
  selectTask,
  hidePanel,
  closePanel,
  handleGeneralUploadProgression,
  setCurrentUploadedFile,
  setProgressStatus,
  onConfirmTransferStart,
  onConfirmTransferSuccess,
  onConfirmTransferFailure,
  setIdea,
  setIdeaType,
  setIdeaComment,
  startLoadingComponentsForProject,
  finishLoadingComponentsForProject,
  startLoadingAllComponentsUploadedByUser,
  finishLoadingAllComponentsUploadedByUser,
  setComponentsNotInCurrentProject,
  removeIdeasFromDashboard,
  setSelectableVersionsOptions,
  resetVersionsIfFromDifferentProject,
} = transferManager.actions;

export default transferManager.reducer;

// THUNKS //

export const clearTransferPanel = ({ accountId, uploadedFiles }) => async (
  dispatch
) => {
  try {
    const deletePromises = [];
    uploadedFiles.forEach((uploadedFile) => {
      deletePromises.push(
        removeFileToS3(uploadedFile.fileInput.uri, accountId).catch((e) =>
          handleError(e.message)
        )
      );
    });
    await Promise.all(deletePromises);
    dispatch(closePanel());
  } catch (e) {
    handleError(e);
  }
};

export const startUploadProcess = ({ personId, files, accountId }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      transferManager: {
        targetParentComponent,
        targetLatestVersionComponent,
        selectedComponentIdToShare,
        selectedProjectId,
        location,
      },
    } = getState();

    const newUploadedFiles = getPayloads({
      files,
      personId,
      targetParentComponent,
      targetLatestVersionComponent,
    });

    dispatch(addUploadsFiles({ uploadedFiles: newUploadedFiles }));

    const {
      transferManager: {
        uploadedFiles, // this must be here to get updated uploadedFiles
      },
    } = getState();

    if (selectedProjectId === "Dashboard") {
      uploadedFiles.forEach((item, position) => {
        if (item.isIdea) {
          dispatch(
            setUploadedComponentIsIdeaOrNot({ position, isIdea: false })
          );
        }
      });
    }
    uploadedFiles.forEach((item, position) => {
      //If in idea panel then set the default to idea
      if (location.ideas) {
        dispatch(setUploadedComponentIsIdeaOrNot({ position, isIdea: true }));
      } else if (item.isVersion && targetParentComponent) {
        const isSameType = item.label === targetParentComponent["__typename"];
        // If the parent selected version is not on the list or is a different type then remove the version
        if (!isSameType) {
          dispatch(
            setComponentVersion({
              position,
              isUploadType: true,
              option: "",
            })
          );
        }
      }
    });

    for (let i = 0; i < newUploadedFiles.length; i++) {
      const uploadedFile = newUploadedFiles[i];
      const properPositionInList = uploadedFiles.findIndex(
        (file) => file.idForComparison === uploadedFile.idForComparison
      );
      if (properPositionInList < 0) {
        throw new Error("Wrong idForComparison");
      }
      if (uploadedFile.isUploaded || uploadedFile.isUploading) {
        continue;
      }

      //TODO do we want to start the upload from zero or start from a division of the total number of files?
      const progressCallback = (progress) => {
        setProgressBar(progress, (status) =>
          dispatch(setProgressStatus(status))
        );
        dispatch(
          handleGeneralUploadProgression({
            numOfUploads: newUploadedFiles.length,
            currentPosition: i + 1,
            currentProgress: getProgress(progress),
          })
        );
      };

      dispatch(
        setStartedUploadingComponent({ position: properPositionInList })
      );
      dispatch(setCurrentUploadedFile({ uploadedFile }));

      const storageInput = {
        ...uploadedFile.storageInput,
        file: files[i].file,
      };
      const obj = await uploadFileToS3(
        storageInput,
        progressCallback,
        uploadedFile.isSourceFile
      );
      const componentId = obj.key.split("/")[1];
      const fileKey = obj.key;
      dispatch(
        completeUploadForUploadedFile({
          accountId,
          position: properPositionInList,
          componentId,
          fileKey,
        })
      );

      if (!selectedComponentIdToShare && selectedProjectId !== "Dashboard") {
        dispatch(
          setSelectedComponentToShare() // dispatch without payload to set default
        );
      }
    }
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setCurrentUploadedFile({ uploadedFile: null }));
  }
};

function createGroupWrappings(
  payload,
  selectedProjectId,
  sameSpaceComponentsByGroupAndOrdered
) {
  const componentGroup = payload.componentGroup;
  const {
    componentOrderWeight,
    componentGroupOrderWeight,
  } = getComponentGroupAndWeights({
    componentGroup,
    selectedProjectId,
    sameSpaceComponentsByGroupAndOrdered,
  });
  return {
    componentGroup: payload.componentGroup,
    componentGroupOrderWeight,
    componentOrderWeight,
    component: [],
  };
}

export const onConfirmTransfer = (payload) => async (dispatch, getState) => {
  const { personId } = payload;
  dispatch(onConfirmTransferStart());
  const {
    transferManager: {
      projectInvitationPayload,
      uploadedFiles,
      importedComponents,
      isNewProject,
      newProjectName,
      selectedProjectId,
      selectedComponentIdToShare,
      sharedComponentUri,
      selectedTaskId,
      allProjectsGroupedComponents,
      idea,
    },
  } = getState();

  let isAnyWorkUploaded = false;

  let project;
  if (isNewProject) {
    try {
      const payload = {
        projectId: selectedProjectId,
        title: newProjectName,
        status: "New",
        partyId: personId,
      };
      project = await createEntity("Project", payload);
      if (!project || (project && !project.id)) {
        handleError(
          "Something went wrong while trying to create a new project"
        );
        dispatch(
          onConfirmTransferFailure({
            error: "Something went wrong while trying to create a new project",
          })
        );
        return;
      }
    } catch (err) {
      handleError(
        "Something went wrong while trying to create a new project, error: " +
          err
      );
      dispatch(onConfirmTransferFailure({ error: err.toString() }));
      return;
    }
  }

  let savedIdea;

  if (idea) {
    try {
      const payload = {
        url: idea.url,
        projectId: selectedProjectId,
        personId: idea.personId,
      };
      savedIdea = await createNewIdeaWithComment({
        type: idea.type ? idea.type : "Text",
        payload,
        comment: idea.comment,
      });
    } catch (e) {
      dispatch(onConfirmTransferFailure({ error: e.toString() }));
      handleError(e);
    }
  }
  const groupedComponents = {};

  // 0. Filter all the ids of references
  let referencesUris = uploadedFiles
    .filter((file) => file.isIdea)
    .map((file) => file.payload.fileInput.uri);
  referencesUris = referencesUris.concat(
    importedComponents
      .filter((file) => file.isIdea)
      .map((file) => file.payload.fileInput.uri)
  );

  const sameSpaceComponentsByGroupAndOrdered =
    selectedProjectId !== "Dashboard" &&
    getComponentsInSelectedProjectByGroupAndOrdered({
      projectId: selectedProjectId,
      groupedComponents: getSameSpaceGroupedComponents(
        selectedProjectId,
        allProjectsGroupedComponents
      ),
    });

  // 1. Create promises with ideas and components
  const promises = uploadedFiles
    .map((input, i) => {
      const { payload, isIdea } = input;

      if (!isIdea) {
        isAnyWorkUploaded = true;
      }

      groupedComponents[payload.fileInput.uri] = createGroupWrappings(
        payload,
        selectedProjectId,
        sameSpaceComponentsByGroupAndOrdered
      );

      return addInput({
        input,
        selectedProjectId,
        previouslyAddedFiles: uploadedFiles.slice(0, i),
        sameSpaceComponentsByGroupAndOrdered,
      });
    })
    .concat(
      importedComponents.map((input) => {
        const { payload, isIdea } = input;

        if (!isIdea) {
          isAnyWorkUploaded = true;
        }

        groupedComponents[payload.fileInput.uri] = createGroupWrappings(
          payload,
          selectedProjectId,
          sameSpaceComponentsByGroupAndOrdered
        );

        return addInput({
          input,
          selectedProjectId,
          sameSpaceComponentsByGroupAndOrdered,
        });
      })
    );

  try {
    // 2. post requests
    const results = await Promise.all(promises);

    // 3. Filter return types for type
    const newComponents = Object.keys(groupedComponents).reduce((arr, uri) => {
      const component = results.filter((c) => c.file[0].uri === uri);
      if (component.length) {
        return arr.concat({ ...groupedComponents[uri], component });
      }
    }, []);

    const references = [];
    referencesUris.forEach((refUri) => {
      const reference = results.find((c) => c.file[0].uri === refUri);
      if (reference) {
        references.push(reference);
      }
    });

    // filter components from referencesUris
    const onlyComponents = newComponents.filter(
      (c) => references.find((r) => r.id === c.component[0].id) == null
    );

    //pass component with group data to conform to the schema
    const { components, result } = normalizeComponents(onlyComponents);

    // 4. Create shared component if selected
    let sharedComponent = null;
    const label = get(
      uploadedFiles.find(
        (comp) =>
          get(comp, "payload.componentInput.id") === selectedComponentIdToShare
      ),
      "label",
      get(
        importedComponents.find(
          (comp) =>
            get(comp, "payload.componentInput.id") ===
            selectedComponentIdToShare
        ),
        "label",
        ""
      )
    );
    if (selectedComponentIdToShare && sharedComponentUri) {
      sharedComponent = await createEntity("SharedComponentWithUri", {
        personId,
        componentId: selectedComponentIdToShare,
        shareComponentUri: sharedComponentUri,
        label,
      });
    }

    // 5. Create invitation if present
    let projectInvitation = null;
    if (projectInvitationPayload) {
      if (projectInvitationPayload.isInvite) {
        projectInvitation = await createEntity(
          "InvitationWithSharedComponent",
          projectInvitationPayload.payload
        );
      } else {
        projectInvitation = await addRelationship(
          "AddProjectMembersWithSharedComponent",
          projectInvitationPayload.payload
        );
      }
    }

    // 6. Add components to redux (there is a listener here)
    dispatch(
      onConfirmTransferSuccess({
        project,
        idea: savedIdea,
        references,
        components,
        result,
        selectedComponentIdToShare,
        sharedComponent,
        projectId: selectedProjectId,
        projectInvitation,
      })
    );
  } catch (e) {
    dispatch(onConfirmTransferFailure({ error: e.toString() }));
    handleError(e);
  }

  if (selectedTaskId) {
    dispatch(
      updateTask({
        personId,
        projectId: selectedProjectId,
        input: { id: selectedTaskId, status: TaskStatus.DONE },
      })
    );
  }

  dispatch(closePanel());
  const finalPath = isAnyWorkUploaded ? "/work" : "/ideas";
  if (selectedProjectId !== "Dashboard") {
    dispatch(push(`/project/${selectedProjectId}${finalPath}`));
  }
};

export const getAllComponentsUploadedByUser = ({ personId }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      transferManager: { selectedProjectId },
    } = getState();
    dispatch(startLoadingAllComponentsUploadedByUser());

    const latestComponents = await readEntity("AllUserFilesLimitedProps", {
      personId,
      role: "uploader",
      isArchived: false,
    });
    const componentsNotInCurrentProject = getlatestVersionsByProject(
      latestComponents,
      personId,
      selectedProjectId
    );
    dispatch(finishLoadingAllComponentsUploadedByUser());
    dispatch(
      setComponentsNotInCurrentProject({
        componentsNotInCurrentProject,
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(finishLoadingAllComponentsUploadedByUser({ error }));
  }
};

export const setupState = ({ personId }) => async (dispatch, getState) => {
  const {
    transferManager: {
      selectedProjectId,
      importedComponents,
      uploadedFiles,
      selectedComponentIdToShare,
    },
  } = getState();
  if (selectedProjectId === "Dashboard") {
    // Users can not have add work ideas in dashboard
    if (importedComponents.concat(uploadedFiles).find((c) => c.isIdea)) {
      dispatch(removeIdeasFromDashboard());
    }

    // In dashboard the user shouldn't be able to invite or share
    if (selectedComponentIdToShare) {
      dispatch(stopShareComponent());
    }
    dispatch(stopInviteToProject());
  }

  //In project the Share always defaults to the first file
  if (selectedProjectId !== "Dashboard") {
    const componentId = get(
      uploadedFiles,
      "[0]payload.componentInput.id",
      get(importedComponents, "[0]payload.componentInput.id", "")
    );
    dispatch(setSelectedComponentToShare({ componentId }));
  }
  dispatch(
    getAllProjectsGroupedComponents({ projectId: selectedProjectId, personId })
  );

  dispatch(resetVersionsIfFromDifferentProject());
};

export const getAllProjectsGroupedComponents = ({
  projectId,
  personId,
}) => async (dispatch) => {
  try {
    dispatch(startLoadingComponentsForProject({ projectId }));

    let allProjectsGroupedComponents;
    if (projectId === "Dashboard") {
      allProjectsGroupedComponents = await readEntity(
        "AllDashboardComponents",
        {
          personId,
          role: "uploader",
          componentArchived: false,
          projectArchived: true,
        }
      );
      // quick fix: filter to remove versions (AllDashboardComponents returns duplicates)
      const versions = allProjectsGroupedComponents.reduce((arr, c) => {
        if (c.versions) {
          return [...arr, ...c.versions];
        }
        return arr;
      }, []);

      allProjectsGroupedComponents = allProjectsGroupedComponents.filter(
        (c) => !versions.find((v) => v.id === c.id)
      );
    } else {
      const allProjectsComponents = await readEntity("AllProjectsComponents", {
        projectIds: [projectId],
      });

      allProjectsGroupedComponents = getSameSpaceGroupedComponents(
        projectId,
        allProjectsComponents
      );
    }

    if (allProjectsGroupedComponents) {
      dispatch(finishLoadingComponentsForProject());
      dispatch(
        setAllProjectsGroupedComponents({
          allProjectsGroupedComponents,
        })
      );
      dispatch(setupSelectableVersionsOptions({ projectId }));
    } else {
      throw new Error(
        "allProjectsGroupedComponents error, returned with: ",
        allProjectsGroupedComponents
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(finishLoadingComponentsForProject({ error: e }));
  }
};

export const changeProject = ({ newProjectId, personId }) => async (
  dispatch
) => {
  dispatch(setSelectedProject({ newProjectId }));

  dispatch(setupState({ personId }));
};

export const setupSelectableVersionsOptions = ({ projectId }) => async (
  dispatch,
  getState
) => {
  const {
    transferManager: {
      selectedProjectId, // selectedProjectId can be different than projectId passed as prop
      allProjectsGroupedComponents,
    },
    projects: { ids, entities },
  } = getState();

  if (!allProjectsGroupedComponents) {
    throw new Error("No allProjectsGroupedComponents");
  }
  const latestVersionsOfComponents = getLatestVersionsOfComponents(
    allProjectsGroupedComponents
  );

  let selectableVersionsOptions = getSelectableVersionsOptions(
    selectedProjectId,
    latestVersionsOfComponents
  );

  // persist a list of project inside the version dropdown when in Dashboard
  if (selectedProjectId === "Dashboard") {
    const projectNamePlaceholders = ids
      .filter((id) => id !== projectId)
      .map((id) => {
        const { title } = entities[id];
        return {
          type: "None", // TODO: improve
          projectId: id,
          projectName: title,
        };
      });
    // if clicked project does not have any suitable components
    if (
      selectableVersionsOptions.length === 1 &&
      selectableVersionsOptions[0].projectId === "Dashboard" &&
      entities[projectId]
    ) {
      selectableVersionsOptions.push({
        type: "None", // TODO: improve
        projectId,
        projectName: entities[projectId].title,
        value: "No matching components",
      });
    }

    selectableVersionsOptions = [
      ...selectableVersionsOptions,
      ...projectNamePlaceholders,
    ];
  }

  dispatch(
    setSelectableVersionsOptions({
      selectableVersionsOptions,
    })
  );
};

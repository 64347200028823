import {isUrlAnImage} from "../../utils/urlUtils";

export function getReferenceType(url) {
    if(!url) return undefined;
    let referenceType = isReactPlayerType(url);
    if(referenceType) return referenceType;
    referenceType = isSpotifyLink(url);
    if(referenceType) return referenceType;
    referenceType = isBandCampLink(url);
    if(referenceType) return referenceType;
    referenceType = isFacebookVideoLink(url);
    if(referenceType) return referenceType;
    referenceType = isDocs(url);
    if(referenceType) return referenceType;
    const isImage = isUrlAnImage(url);
    if(isImage) return "Image";
    return referenceType;
}

export function isReactPlayerType(url) {
    const videos = ["youtube.com/watch", "youtu.be", "vimeo.com", "wistia.com", "dailymotion.com", "twitch.tv"];
    const recordings = ["soundcloud.com", "mixcloud.com"];
    let foundType = undefined;
    videos.forEach((possibleWebsite) => {
        if(url.includes(possibleWebsite)) {
            foundType = "Video";
        }
    });
    recordings.forEach((possibleWebsite) => {
        if(url.includes(possibleWebsite)) {
            foundType = "Recording";
        }
    });
    return foundType;
}

export function isSpotifyLink(url) {
    if(url.includes("spotify.com")) {
        return "Recording";
    }
    return undefined;
}

export function isBandCampLink(url) {
    if(url.includes("bandcamp.com")) {
        return "Recording";
    }
    return undefined;
}

export function isFacebookVideoLink(url) {
    if(url.includes("facebook.com/facebook/videos/")) {
        return "Video";
    }
    return undefined;
}

export function isDocs(url) {
    const docs = ["docs.google.com"];
    let foundType = undefined;
    docs.forEach((possibleWebsite) => {
        if(url.includes(possibleWebsite)) {
            foundType = "Text";
        }
    });
    return foundType;
}

function unShortenYoutubeVideo(url) {
    if(url.includes("youtu.be/")) {
        return url.replace("youtu.be/", "youtube.com/watch?v=");
    }
    return url;
}

export function linkCleaner(url) {
    if(!url) return url;
    let updatedUrl = unShortenYoutubeVideo(url);
    return updatedUrl;
}

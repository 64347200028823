import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  readEntity,
  deleteEntity,
  updateEntity,
  createEntity,
  removeRelationship,
} from "../../data/delicDataClient";
import { isMemberPending } from "../../utils/basic"
import { sortByLastModifiedDate } from "../../utils/helpers";
import { projectEntity } from "../schemas";
import { normalize } from "normalizr";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import { fetchTasksInProject } from "./tasksSlice";
import { getLatestComponentsSuccess } from "./componentsSlice";
import { push } from "connected-react-router";
import { v4 as uuid } from "uuid";
import { onConfirmTransferSuccess } from "./transferManagerSlice";

export const projectsAdapter = createEntityAdapter();

const initialState = projectsAdapter.getInitialState({
  isLoading: false,
  error: false,
});

const projects = createSlice({
  name: "projects",
  initialState,
  reducers: {
    onStart(state) {
      state.isLoading = true;
    },
    getAllProjectsSuccess(state, { payload }) {
      const { allProjects } = payload;
      projectsAdapter.upsertMany(state, allProjects);
      state.error = null;
      state.isLoading = false;
    },
    onFail(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getProjectSuccess(state, { payload }) {
      const { project } = payload;
      const normalized = normalize(project, projectEntity);
      const p = normalized.entities.project[project.id];
      projectsAdapter.upsertOne(state, p);
      state.isLoading = false;
      state.error = null;
    },
    deleteProjectSuccess(state, { payload }) {
      const { projectId } = payload;
      projectsAdapter.removeOne(state, projectId);
    },
    createProjectSuccess(state, { payload }) {
      const { project } = payload;
      projectsAdapter.addOne(state, project);
    },
    removeMemberFromProjectSuccess(state, { payload }) {
      const { personId, projectId } = payload;
      const project = {
        ...state.entities[projectId],
        members: state.entities[projectId].members.filter(
          (memberId) => memberId !== personId
        ),
        pendingInvitations: state.entities[projectId].pendingInvitations.filter(
          (invitation) => get(invitation, "invitee[0].id") !== personId
        ),
      };
      projectsAdapter.upsertOne(state, project);
      state.entities[projectId].removeMemberError = null;
    },
    removeMemberFromProjectFailed(state, { payload }) {
      const { projectId, error } = payload;
      state.entities[projectId].removeMemberError = error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestComponentsSuccess, (state, { payload }) => {
      const { project } = payload;
      if (project) {
        projectsAdapter.upsertMany(state, project);
      }
    });
    builder.addCase(onConfirmTransferSuccess, (state, { payload }) => {
      const { project, projectInvitation } = payload;
      if (project) {
        const normalized = normalize(project, projectEntity);
        const p = normalized.entities.project[project.id];
        projectsAdapter.upsertOne(state, p);
      }
      if (projectInvitation) {
        const invitee = projectInvitation.invitee[0];

        // add invitee's 'person' to project state
        state.entities[payload.projectId].members.push(
          invitee.id
        );

        if (isMemberPending(invitee)){
          // add invitation to project's 'pendingInvites' state.
          // (N.B pushing the whole object (and not just id) as 
          //  invitee property is required for removing pending invitees)
          state.entities[payload.projectId].pendingInvitations.push(projectInvitation);
        }
      }
    });
  },
});

export const {
  selectById: selectProjectById,
  selectIds: selectProjectIds,
  selectEntities: selectProjectEntities,
  selectAll: selectAllProjects,
  selectTotal: selectTotalProjects,
} = projectsAdapter.getSelectors((state) => state.projects);

export const {
  onStart,
  getAllProjectsSuccess,
  onFail,
  getProjectSuccess,
  deleteProjectSuccess,
  createProjectSuccess,
  removeMemberFromProjectSuccess,
  removeMemberFromProjectFailed,
} = projects.actions;

export default projects.reducer;

export const selectProjectOwner = (projectId) =>
  createSelector(
    [
      (state) => state.projects && state.projects.entities[projectId],
      (state) => state.members && state.members.entities,
    ],
    (project, members) => {
      if (project && project.owners && members) {
        return members[project.owners[0]];
      }
    }
  );

export const selectAllProjectsThatUserBelongsTo = (personId) =>
  createSelector(
    [
      (state) => state.projects && state.projects.ids,
      (state) => state.projects && state.projects.entities,
    ],
    (projectIds, projects) => {
      if (projectIds.length && projects) {
        return orderBy(
          projectIds.reduce((arr, id) => {
            const project = projects[id];
            // don't show projects that were archived by user or used removed themselves
            if (
              project.isArchived === true ||
              (project.members &&
                project.owners &&
                !project.members
                  .concat(project.owners)
                  .some((id) => id === personId))
            ) {
              return arr;
            }
            return arr.concat(project);
          }, []),
          ["lastModifiedDate.formatted", "creationDate.formatted"],
          ["desc"]
        );
      }
    }
  );

// vvvvvvvvvvv         THUNKS         vvvvvvvvvvv //

// ------------------- FETCH ------------------ //

export const fetchAllProjects = ({ personId }) => async (dispatch) => {
  try {
    dispatch(onStart());
    const res = await readEntity("AllProjectsByOwnerOrMemberId", { personId });
    if (res) {
      const allProjects = [...res.projectsMembership, ...res.projectsOwned];
      allProjects.sort(sortByLastModifiedDate);
      dispatch(getAllProjectsSuccess({ allProjects }));
    } else {
      dispatch(onFail("Error when fetching projects"));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

export const fetchProjectDetails = ({ personId, projectId }) => async (
  dispatch
) => {
  try {
    dispatch(onStart());
    const project = await readEntity("Project", { personId, projectId });
    if (project) {
      dispatch(getProjectSuccess({ project }));
    } else {
      dispatch(onFail("Error when fetching projects"));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

// ------------------- UPDATE ------------------ //

export const updateProject = ({ personId, input }) => async (dispatch) => {
  try {
    dispatch(onStart());
    const project = await updateEntity("Project", { personId, input });
    if (project) {
      dispatch(getProjectSuccess({ project }));
    } else {
      dispatch(onFail("Error when updating projects"));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

export const deleteProject = ({ personId, projectId }) => async (dispatch) => {
  try {
    dispatch(onStart());
    const res = await deleteEntity("Project", { personId, projectId });
    if (res) {
      dispatch(deleteProjectSuccess({ projectId }));
    } else {
      dispatch(onFail("Error when deleting projects"));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

// ------------------- CREATE ------------------ //

export const createProject = ({ title, status, partyId }) => async (
  dispatch
) => {
  try {
    const projectId = uuid();
    const project = await createEntity("Project", {
      projectId,
      title,
      status,
      partyId,
    });
    if (project && project.id) {
      dispatch(createProjectSuccess({ project }));
      dispatch(push(`/project/${project.id}/ideas`));
    } else {
      dispatch(onFail("Error when creating projects"));
    }
  } catch (err) {
    dispatch(onFail(err.toString()));
  }
};

// ------------------- MEMBERSHIP ------------------ //

export const removeMemberFromProject = ({ memberId, projectId }) => async (
  dispatch
) => {
  try {
    const removedMember = await removeRelationship("RemoveProjectMember", {
      personId: memberId,
      projectId,
      taskStatus: "To Do",
    });
    if (removedMember.length) {
      dispatch(
        removeMemberFromProjectSuccess({
          personId: removedMember.pop().id,
          projectId,
        })
      );
    } else {
      dispatch(
        removeMemberFromProjectFailed({
          projectId,
          error: "Error when creating projects",
        })
      );
    }
  } catch (err) {
    dispatch(
      removeMemberFromProjectFailed({ projectId, error: err.toString() })
    );
  }
};

export const softRemoveSelfFromProject = ({
  personId,
  projectId,
  goToDashboardCallback,
}) => async (dispatch) => {
  try {
    const res = await removeRelationship("SoftRemoveSelfFromProject", {
      personId,
      projectId,
    });
    if (res) {
      dispatch(deleteProjectSuccess({ projectId }));
      dispatch(fetchTasksInProject({ personId, projectId }));
      goToDashboardCallback();
    } else {
      dispatch(
        onFail({ projectId, error: "Error when soft remove from project" })
      );
    }
  } catch (err) {
    dispatch(onFail({ projectId, error: err.toString() }));
  }
};

export const hardRemoveSelfFromProject = ({
  personId,
  projectId,
  callback,
}) => async (dispatch) => {
  try {
    const res = await removeRelationship("HardRemoveSelfFromProject", {
      personId,
      projectId,
    });
    if (res) {
      dispatch(deleteProjectSuccess({ projectId }));
      callback();
    } else {
      dispatch(
        onFail({ projectId, error: "Error when hard remove from project" })
      );
    }
  } catch (err) {
    dispatch(onFail({ projectId, error: err.toString() }));
  }
};

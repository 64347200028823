import {
  fileFormatGroups,
  groups as FileFormatGroups,
} from "../content/workFileFormatsGroups";

export function getFileExtension(fileName, ext) {
  return ext ? ext : (fileName.match(/(\.)\w+/g) || []).pop();
}

export function getGroupFromFileType(filetype, mimeType) {
  if (filetype) {
    const formatGroup = fileFormatGroups[filetype.toLowerCase()];
    if (formatGroup) {
      return formatGroup.group;
    }
  }

  if (!mimeType) return FileFormatGroups.GENERIC_FILE;

  if (mimeType.includes("video")) {
    return FileFormatGroups.VIDEO_FILE;
  }

  if (mimeType.includes("image")) {
    return FileFormatGroups.IMAGE_FILE;
  }

  return FileFormatGroups.GENERIC_FILE;
}

export function getFileExtFromCleanedExt(cleanedExt) {
  if (cleanedExt) {
    return "." + cleanedExt.toLowerCase();
  }
}

export function getSoftwareFromFileType(filetype) {
  if (filetype) {
    const formatGroup = fileFormatGroups[filetype.toLowerCase()];
    if (formatGroup) {
      return formatGroup.software;
    }
  }

  return "";
}

export function getSoftwareIcon(filetype) {
  const software = getSoftwareFromFileType(filetype);
  if (!software || software === "Generic") return "";

  return "/icons/" + software + ".svg";
}

export function generateFileTypeEnum(fileExtension) {
  let fileEnum = "OTHER";
  if (fileExtension) {
    let uppercased = fileExtension
      .trim()
      .replace(/[^a-zA-Z0-9\s]+/g, "")
      .toUpperCase();
    if (uppercased.match(/^[A-Z]/g)) {
      if (uppercased === "JPEG") return "JPG";
      if (uppercased === "AIFF") return "AIF";
      fileEnum = uppercased;
    } else {
      fileEnum = "_" + uppercased;
    }
  }
  return validateFileTypeEnum(fileEnum);
}

export function getSoftware(fileExtension) {
  if (fileExtension) {
    return softwareMapper(generateFileTypeEnum(fileExtension));
  }
}

function softwareMapper(type) {
  switch (type) {
    case "BAND":
      return "GarageBand";
    case "BWPROJECT":
      return "Bitwig";
    case "PROJECT":
      return "Studio One";
    case "CWB":
      return "Cakewalk";
    case "CWP":
      return "Cakewalk";
    case "ALS":
      return "Ableton";
    case "ASD":
      return "Ableton";
    case "ASK":
      return "Ableton";
    case "ADG":
      return "Ableton";
    case "ADV":
      return "Ableton";
    case "ALC":
      return "Ableton";
    case "AGR":
      return "Ableton";
    case "AMS":
      return "Ableton";
    case "AMXD":
      return "Ableton";
    case "AUP":
      return "Audacity";
    case "PTX":
      return "Pro Tools 10";
    case "PTF":
      return "Pro Tools 7";
    case "PTS":
      return "Pro Tools 6 and earlier";
    case "CPR":
      return "Cubase";
    case "FLP":
      return "FL Studio";
    case "RPPRJ":
    case "RPP":
      return "REAPER";
    case "ACID":
      return "ACID Pro";
    // case "": return "Audiotool";
    case "OMF":
      return "Digital Performer";
    // case "": return "Fairlight";
    case "LOGICX":
      return "Logic Pro X";
    // case "": return "Mixbus";
    case "MX3":
      return "Mixcraft 3";
    case "MX4":
      return "Mixcraft 4";
    case "MX5":
      return "Mixcraft 5";
    case "MXTEMPLATE":
      return "Mixcraft 5";
    case "NPR":
      return "Nuendo";
    case "RSN":
      return "Reason";
    case "RPS":
      return "Reason";
    case "RECORD":
      return "Reason";
    case "REASON":
      return "Reason";
    case "RSNDEMO":
      return "Reason";
    case "XRNS":
      return "Renoise";
    case "VIP":
      return "Samplitude";
    case "FRG":
      return "Sound Forge";
    case "PRJ":
      return "Tracktion";
    case "MMM":
      return "MAGICX Music Marker";
    case "MXC2":
      return "MAGICX Music Marker";
    case "TAK":
      return "MAGICX Music Marker";
    case "ZGR":
      return "BeatCreator/BeatSlicer";
    case "TL":
      return "Open TL";
    case "KRISTAL":
      return "Kristal Audio Engine Projects";
    case "CAPTURE":
      return "PreSonus Capture Sessions";
    case "XML":
      return "Steinberg Cubase Track Archives";
    case "STEINBERG-PROJECT":
      return "Steinberg Sequel Projects";
    case "PD":
      return "Pure Data";
    case "SCD":
      return "Supercollider";
    case "VCV":
      return "VCV Rack";
    case "MAXPAT":
      return "MAX/MSP";
    case "COMPONENT":
      return "AudioUnit";
    case "AU":
      return "AudioUnit";
    case "VST":
      return "VST";
    default:
      return "Undetermined";
  }
}

export const COMPONENT_TYPES = {
  SourceFile: "SourceFile",
  Recording: "Recording",
  Video: "Video",
};

export const toBytes = {
  "1.9GB": 2147483647,
  "6GB": 6442450944,
  "500MB": 5242880,
};
function validateFileTypeEnum(fileType) {
  return fileTypeEnums.includes(fileType) ? fileType : "OTHER";
}
const fileTypeEnums = [
  "_3DM",
  "_3G2",
  "_3GP",
  "_7Z",
  "_8BI",
  "AAC",
  "AAF",
  "AC3",
  "ACCDB",
  "ACID",
  "ADG",
  "ADV",
  "AGR",
  "AI",
  "AIF",
  "ALC",
  "ALS",
  "AMS",
  "AMXD",
  "ANA",
  "APP",
  "ASD",
  "ASF",
  "ASK",
  "ASP",
  "ASX",
  "AU",
  "AUD",
  "AUP",
  "AVI",
  "BAK",
  "BAND",
  "BAT",
  "BIN",
  "BMP",
  "BT",
  "BWF",
  "BWPROJECT",
  "C",
  "CAB",
  "CAF",
  "CAPTURE",
  "CER",
  "CFG",
  "CGI",
  "CLASS",
  "COM",
  "COMPONENT",
  "CPL",
  "CPP",
  "CPR",
  "CS",
  "CSR",
  "CSS",
  "CSV",
  "CUR",
  "CWB",
  "CWP",
  "DAT",
  "DB",
  "DBX",
  "DEB",
  "DLL",
  "DMG",
  "DMP",
  "DOC",
  "DOCX",
  "DRV",
  "DRW",
  "DSD",
  "DTD",
  "DV",
  "DWG",
  "DXF",
  "EFX",
  "EPS",
  "EXE",
  "EXS",
  "FLA",
  "FLAC",
  "FLP",
  "FLV",
  "FNT",
  "FON",
  "FRG",
  "GADGET",
  "GAM",
  "GHO",
  "GIF",
  "GPX",
  "GZ",
  "HQX",
  "HTM",
  "HTML",
  "IFF",
  "INDD",
  "INI",
  "ISO",
  "JAR",
  "JAVA",
  "JPG",
  "JS",
  "JSP",
  "KEY",
  "KEYCHAIN",
  "KML",
  "KRISTAL",
  "LNK",
  "LOG",
  "LOGICX",
  "M",
  "M3U",
  "M4A",
  "MAX",
  "MAXPAT",
  "MDB",
  "MID",
  "MIM",
  "MMM",
  "MOV",
  "MP3",
  "MP4",
  "MPA",
  "MPG",
  "MSG",
  "MX3",
  "MX4",
  "MX5",
  "MXC2",
  "MXTEMPLATE",
  "NES",
  "NEU",
  "NKI",
  "NKS",
  "NPR",
  "OGG",
  "OMF",
  "OPUS",
  "ORI",
  "OSC",
  "OTF",
  "OTHER",
  "PAGES",
  "PCM",
  "PCT",
  "PD",
  "PDB",
  "PDF",
  "PHP",
  "PIF",
  "PKG",
  "PL",
  "PLIST",
  "PLN",
  "PLUGIN",
  "PNG",
  "PPS",
  "PPT",
  "PPTX",
  "PRF",
  "PRJ",
  "PROJECT",
  "PS",
  "PSD",
  "PSPIMAGE",
  "PTF",
  "PTS",
  "PTX",
  "QXD",
  "QXP",
  "RA",
  "RAR",
  "REASON",
  "RECORD",
  "RELS",
  "RM",
  "ROM",
  "RPPRJ",
  "RPP",
  "RPS",
  "RSN",
  "RSNDEMO",
  "RSS",
  "RTF",
  "SAV",
  "SB2",
  "SBK",
  "SCD",
  "SD2",
  "SDF",
  "SF2",
  "SIT",
  "SITX",
  "SQL",
  "STEINBERGPROJECT",
  "SVG",
  "SWF",
  "SYS",
  "TAK",
  "TAR",
  "TARGZ",
  "THM",
  "TIF",
  "TL",
  "TMP",
  "TOAST",
  "TS",
  "TTF",
  "TXT",
  "UUE",
  "VB",
  "VCD",
  "VCF",
  "VCV",
  "VIP",
  "VOB",
  "VST",
  "WAV",
  "WKS",
  "WMA",
  "WMV",
  "WPD",
  "WPS",
  "WSF",
  "XHTML",
  "XLL",
  "XLS",
  "XLSX",
  "XML",
  "XRNS",
  "YUV",
  "ZGR",
  "ZIP",
  "ZIPX",
  "MPEG",
  "WEBP",
  "AVIF",
  "JFIF",
  "TIFF",
  "WEBM",
  "MPEG4",
  "_3GPP",
  "MPEGS",
  "XPS",
];

import { createAction } from "@reduxjs/toolkit";

export const upsertCommentsFromCommentables = createAction(
  "upsertCommentsFromCommentables",
  function getComments(commentables) {
    const comments = commentables.reduce(
      (arr, t) => [...arr, ...t.comments],
      []
    );
    return {
      payload: {
        comments,
      },
    };
  }
);

export const upsertComments = createAction(
  "upsertComments",
  function getComments(comments) {
    return {
      payload: {
        comments,
      },
    };
  }
);

export const createCommentSuccess = createAction("createCommentSuccess");

export const resetStore = createAction("resetStore");

import { readEntity, updateEntity } from "../../data/delicDataClient";
import { handleError } from "../../utils/basic";

export async function updatePerson(dispatch, { input, callBack }) {
    try {
        const res = await updateEntity("Person", { input });
        if (res) {
            dispatch({ type: "fetched", payload: res });
            if (callBack) {
                callBack();
            }
        } else {
            throw new Error("Error updating Person");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function acceptTermsAndConditions(dispatch, { cognitoId, input, callBack }, state) {
    try {
        const res = await updateEntity("AcceptTermsAndConditions", { cognitoId, input });
        if (res) {
            dispatch({ type: "fetched", payload: { ... state, ...res} });
            if (callBack) {
                callBack();
            }
        } else {
            throw new Error("Error updating Person");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function fetchPerson(dispatch, { accountId, callBack }) {
    try {
        const res = await readEntity("PersonByAccountId", { accountId });
        if (res) {
            dispatch({ type: "fetched", payload: res });
            if (callBack) {
                callBack();
            }
        } else {
            throw new Error("Error fetching PublicProfile");
        }
    } catch (e) {
        handleError(e);
    }
}

export async function getFounderMemberStatus({ hubspotId, callBack }) {
  try {
    const founderMemberStatusResponse = await readEntity("FounderMemberStatus", { hubspotId });
    if (callBack) {
      callBack(founderMemberStatusResponse);
    }
  } catch (e) {
    handleError(e);
  }
}